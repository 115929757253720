import React from 'react'

function TableHeader(props) {
    let childrenCount = React.Children.count(props.children);
    let childrens = React.Children.map(props.children, child => { return child; });

    let hasFilter = childrenCount > 1;
    let height = hasFilter ? 99 : 44;

    return (
        <div>
            <div className="d-flex flex-column position-fixed" style={{ zIndex: 10000, width: "calc(100vw)" }}>
                <div className="top-button px-3 d-flex">
                    <div className="spacer">
                        {childrens[0]}
                    </div>
                </div>

                {childrens[1] && 
                    <div>
                        <div className="py-2 px-2" style={{ backgroundColor: "whitesmoke" }}>
                            {childrens[1]}
                        </div>
                        <hr className="m-0" />
                    </div>
                }
            </div>
            <div style={{ height: height + "px" }}></div>
        </div>
    )
}


export default TableHeader
