import React, { useEffect } from "react";
import TableHeader from "../../components/common/TableHeader";
import AddButton from "../../components/common/AddButton";
import BackButton from "../../components/common/BackButton";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import DivideHeader from "../../components/common/DivideHeader";
import RefreshButton from "../../components/common/RefreshButton";
import DatePicker from "react-date-picker";
import CurrencyInput from "react-currency-input-field";
import { BridgeRepairDetailModel, BridgeRepairModel } from "./Models";
import BridgeRepairDetailItem from "./BridgeRepairDetailItem";
import { axiosGet, axiosPost } from "../../ApiService/BaseAPI";
import toastr from "toastr";

export default function BridgeRepairAddPage(props) {
  const [bridgeRepair, setBridgeRepair] = useState(new BridgeRepairModel());
  const [bridgeList, setBridgeList] = useState([]);
  document.title = "Thêm thông tin kiểm tra/sửa chữa cầu";
  let bridgeId = useParams().bridgeId;
  let hasBridgeId = bridgeId !== 0 && bridgeId !== undefined && bridgeId != null;

  function loadBridgeList() {
    axiosGet("bridge/all", {}, function (rspns) {
      setBridgeList(rspns.Data);
      //Nếu có bridgeid param thì lấy bridgeId
      if (hasBridgeId) {
        setSelectedBridge(bridgeId);
        setBridgeRepair({ ...bridgeRepair, BridgeId: bridgeId });
      }

      //Nếu không có bridgeId thì lấy bridge đầu tiên trong list
      if (!hasBridgeId) {
        let firstBridge = rspns.Data[0].BridgeId;
        setBridgeRepair({ ...bridgeRepair, BridgeId: firstBridge, TenCayCau: firstBridge.TenCayCau });
        setSelectedBridge(firstBridge);
      }
    });
  }

  useEffect(() => {
    loadBridgeList();
  }, []);

  function addDetail() {
    let newItem = new BridgeRepairDetailModel();
    bridgeRepair.Details.push(newItem);
    setBridgeRepair({ ...bridgeRepair, Details: bridgeRepair.Details });
  }

  function onBridgeChange(id) {
    setSelectedBridge(id);
  }

  function addSuCo() {
    console.log(bridgeRepair);
    axiosPost("bridgeRepair", bridgeRepair, function (rspns) {
      if (rspns.IsSuccess) {
        toastr.success("Thêm thành công");
      }
    });
  }

  function setSelectedBridge(id) {
    const bridge = bridgeList.find((x) => x.BridgeId === parseInt(id));
    if (bridge !== undefined) {
      setBridgeRepair({ ...bridgeRepair, TenCayCau: bridge.TenCayCau, BridgeId: parseInt(id) });
    }
  }

  function deleteDetail(id) {
    let newDetails = bridgeRepair.Details.filter((x) => x.Id !== id);
    setBridgeRepair({ ...bridgeRepair, Details: newDetails });
  }

  //Copy thông tin  thay đổi của detail vào dữ liệu trang mẹ
  function onDetailChanged(item, newItem) {
    item = Object.assign(item, newItem);
    setBridgeRepair({ ...bridgeRepair, Details: bridgeRepair.Details });
  }

  return (
    <div>
      <TableHeader>
        <div className="d-flex">
          <BackButton></BackButton>
          <div className="spacer"></div>
          <RefreshButton text="Reset dữ liệu nhập" className="btn btn-light me-2" onClick={() => {}}></RefreshButton>
          <AddButton
            text="Thêm dữ liệu"
            className="btn-success"
            onClick={() => {
              addSuCo();
            }}></AddButton>
        </div>
      </TableHeader>

      <div className="m-3">
        <h1>
          Thêm thông tin sửa chữa <span className="text-success">{bridgeRepair.TenCayCau}</span>
        </h1>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/sua-chua-cau">Sửa chữa cầu</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            Thêm thông tin sửa chữa
          </li>
        </ol>
        <hr className="my-3"></hr>

        <div className="row">
          <div className="col-md-4 border-end">
            <div>
              <DivideHeader expandable={false}>THÔNG TIN KIỂM TRA / SỬA CHỮA</DivideHeader>
              <div className="collapse show" id="div-thong-tin-chung">
                <div className="d-block mt-2 align-items-center">
                  <h6 className="m-0">Cây cầu</h6>
                  <div className="mt-2"></div>
                  <select
                    className="form-control"
                    value={bridgeRepair.BridgeId}
                    disabled={hasBridgeId}
                    onChange={(e) => {
                      onBridgeChange(e.target.value);
                    }}>
                    {bridgeList.map((x) => (
                      <option key={x.BridgeId} value={x.BridgeId}>
                        {x.TenCayCau}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="d-block mt-2 align-items-center">
                  <h6 className="m-0">Ngày kiểm tra</h6>
                  <div className="mt-2"></div>
                  <input
                    type="date"
                    className="form-control w-100"
                    value={bridgeRepair.NgayKiemTra}
                    onChange={(e) => {
                      setBridgeRepair({ ...bridgeRepair, NgayKiemTra: e.target.value });
                    }}></input>
                </div>

                <div className="d-block mt-2 align-items-center">
                  <h6 className="m-0">Đơn vị kiểm tra</h6>
                  <div className="mt-2"></div>
                  <input
                    className="form-control"
                    value={bridgeRepair.DonViKiemTra}
                    onChange={(e) => {
                      setBridgeRepair({ ...bridgeRepair, DonViKiemTra: e.target.value });
                    }}
                    placeholder="Nhập đơn vị kiểm tra..."></input>
                </div>

                <div className="d-block mt-2 align-items-start">
                  <h6 className="mt-1">Nội dung hư hỏng</h6>
                  <div className="mt-2"></div>
                  <textarea
                    className="form-control"
                    value={bridgeRepair.LoaiHuHong}
                    rows={3}
                    onChange={(e) => {
                      setBridgeRepair({ ...bridgeRepair, LoaiHuHong: e.target.value });
                    }}
                    placeholder="Nhập nội dung hư hỏng..."></textarea>
                </div>

                <div className="d-block mt-2 align-items-center">
                  <h6 className="m-0">Ngày sửa chữa</h6>
                  <div className="mt-2"></div>
                  <input
                    className="form-control w-100"
                    type="date"
                    value={bridgeRepair.NgaySuaChua}
                    onChange={(e) => {
                      setBridgeRepair({ ...bridgeRepair, NgaySuaChua: e.target.value });
                    }}></input>
                </div>

                <div className="d-block mt-2 align-items-center">
                  <h6 className="m-0">Đơn vị sửa chữa</h6>
                  <div className="mt-2"></div>
                  <input
                    className="form-control"
                    value={bridgeRepair.DonViSuaChua}
                    onChange={(e) => {
                      setBridgeRepair({ ...bridgeRepair, DonViSuaChua: e.target.value });
                    }}
                    placeholder="Nhập đơn vị sửa chữa..."></input>
                </div>

                <div className="d-block mt-2 align-items-center">
                  <h6 className="m-0">Chi phí sửa chữa</h6>
                  <div className="mt-2"></div>
                  <CurrencyInput
                    groupSeparator="."
                    decimalSeparator=","
                    decimalsLimit={2}
                    className=" form-control"
                    prefix="đ"
                    value={bridgeRepair.ChiPhiSuaChua}
                    onValueChange={(value, name) => {
                      setBridgeRepair({ ...bridgeRepair, ChiPhiSuaChua: parseFloat(value) });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <DivideHeader expandable={false}>LỊCH SỬ XỬ LÝ</DivideHeader>

            {bridgeRepair.Details.map((item, index) => (
              <div key={item.Id}>
                <div className="mt-2"></div>
                Noi dung: {item.NoiDung}
              </div>
            ))}

            {bridgeRepair.Details.map((item, index) => (
              <div key={item.Id}>
                <div className="mt-2"></div>
                <BridgeRepairDetailItem
                  item={item}
                  onDelete={(e) => deleteDetail(item.Id)}
                  onChange={(newItem) => {
                    onDetailChanged(item, newItem);
                  }}></BridgeRepairDetailItem>
              </div>
            ))}

            <button className="btn btn-success mt-2" onClick={addDetail}>
              <span className="bi bi-plus-lg text-white me-2"></span>Thêm lịch sử xử lý
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
