import React, { useEffect } from "react";
import TableHeader from "../../components/common/TableHeader";
import AddButton from "../../components/common/AddButton";
import BackButton from "../../components/common/BackButton";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import DivideHeader from "../../components/common/DivideHeader";
import RefreshButton from "../../components/common/RefreshButton";
import DatePicker from "react-date-picker";
import CurrencyInput from "react-currency-input-field";
import { BridgeRepairDetailModel, BridgeRepairModel } from "./Models";
import BridgeRepairDetailItem from "./BridgeRepairDetailItem";
import { axiosGet, axiosPut } from "../../ApiService/BaseAPI";
import BridgeModel from "../Bridge/BridgeModel";
import toastr from "toastr";

export default function BridgeRepairEditPage(props) {
  const [bridgeRepair, setBridgeRepair] = useState(new BridgeRepairModel());
  const [bridgeList, setBridgeList] = useState([]);

  const pathParams = useParams();
  let repairId = pathParams.id;
  document.title = "Thông tin kiểm tra/sửa chữa cầu " + repairId;

  function loadBridgeRepairDetail() {
    if (repairId === 0 || repairId === undefined || repairId == null) return;

    axiosGet("bridgerepair/" + repairId, {}, function (rspns) {
      Object.assign(bridgeRepair, rspns.Data);
      bridgeRepair.Details = rspns.Data.Details;

      setBridgeRepair({ ...bridgeRepair, Details: rspns.Data.Details });
      console.log(rspns.Data);
    });
  }

  function loadBridgeList() {
    axiosGet("bridge/all", {}, function (rspns) {
      let bridgeId = rspns.Data[0].BridgeId;
      setBridgeList(rspns.Data);
      setBridgeRepair({ ...bridgeRepair, BridgeId: bridgeId });
    });
  }

  useEffect(() => {
    loadBridgeList();
    loadBridgeRepairDetail();
  }, []);

  useEffect(() => {
    console.log(bridgeRepair);
  }, [bridgeRepair]);

  function addDetail() {
    bridgeRepair.Details.push(new BridgeRepairDetailModel());
    setBridgeRepair({ ...bridgeRepair, Details: bridgeRepair.Details });
  }

  function onBridgeChange(id) {
    setBridgeRepair({ ...bridgeRepair, BridgeId: parseInt(id) });
  }

  function updateSuCo() {
    console.log(bridgeRepair);
    axiosPut("bridgeRepair/" + bridgeRepair.RepairHistoryId, bridgeRepair, function (rspns) {
      if (rspns.IsSuccess) {
        toastr.success("Lưu thành công");
      }
    });
  }

  function deleteDetail(id) {
    let newDetails = bridgeRepair.Details.filter((x) => x.Id !== id);
    setBridgeRepair({ ...bridgeRepair, Details: newDetails });
  }

  //Copy thông tin  thay đổi của detail vào dữ liệu trang mẹ
  function onDetailChanged(item, newItem) {
    item = Object.assign(item, newItem);
    setBridgeRepair({ ...bridgeRepair, Details: bridgeRepair.Details });
  }
  return (
    <div>
      <TableHeader>
        <div className="d-flex">
          <BackButton></BackButton>
          <div className="spacer"></div>
          <RefreshButton
            text="Tải lại dữ liệu"
            className="btn btn-light me-2"
            onClick={() => {
              loadBridgeRepairDetail();
            }}></RefreshButton>
          <AddButton
            text="Lưu dữ liệu"
            className="btn-success"
            onClick={() => {
              updateSuCo();
            }}></AddButton>
        </div>
      </TableHeader>

      <div className="m-4">
        <h1>
          Sửa chữa/kiểm tra <span className="text-success">{bridgeRepair.TenCayCau}</span>
        </h1>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/sua-chua-cau">Sửa chữa cầu</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            Cập nhập thông tin sửa chữa
          </li>
        </ol>
        <hr></hr>

        <div className="row">
          <div className="col-md-4 border-end">
            <div>
              <DivideHeader expandable={false}>THÔNG TIN KIỂM TRA / SỬA CHỮA</DivideHeader>
              <div className="collapse show" id="div-thong-tin-chung">
                <div className="d-block mt-2 align-items-center">
                  <h6 className="m-0">Cây cầu</h6>
                  <div className="mt-2"></div>
                  <select
                    className="form-control"
                    value={bridgeRepair.BridgeId}
                    onChange={(e) => {
                      onBridgeChange(e.target.value);
                    }}>
                    {bridgeList.map((x) => (
                      <option key={x.BridgeId} value={x.BridgeId}>
                        {x.TenCayCau}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="d-block mt-2 align-items-center">
                  <h6 className="m-0">Ngày kiểm tra</h6>
                  <div className="mt-2"></div>
                  <input
                    type="text"
                    className="form-control w-100"
                    value={bridgeRepair.NgayKiemTra}
                    onChange={(e) => {
                      setBridgeRepair({ ...bridgeRepair, NgayKiemTra: e.target.value });
                    }}></input>
                </div>

                <div className="d-block mt-2 align-items-center">
                  <h6 className="m-0">Đơn vị kiểm tra</h6>
                  <div className="mt-2"></div>
                  <input
                    className="form-control"
                    value={bridgeRepair.DonViKiemTra}
                    onChange={(e) => {
                      setBridgeRepair({ ...bridgeRepair, DonViKiemTra: e.target.value });
                    }}
                    placeholder="Nhập đơn vị kiểm tra..."></input>
                </div>

                <div className="d-block mt-2 align-items-start">
                  <h6 className="mt-1">Nội dung hư hỏng</h6>
                  <div className="mt-2"></div>
                  <textarea
                    className="form-control"
                    value={bridgeRepair.LoaiHuHong}
                    rows={3}
                    onChange={(e) => {
                      setBridgeRepair({ ...bridgeRepair, LoaiHuHong: e.target.value });
                    }}
                    placeholder="Nhập nội dung hư hỏng..."></textarea>
                </div>

                <div className="d-block mt-2 align-items-center">
                  <h6 className="m-0">Ngày sửa chữa</h6>
                  <div className="mt-2"></div>
                  <input
                    type="text"
                    className="form-control w-100"
                    value={bridgeRepair.NgaySuaChua}
                    onChange={(e) => {
                      setBridgeRepair({ ...bridgeRepair, NgaySuaChua: e.target.value });
                    }}></input>
                </div>

                <div className="d-block mt-2 align-items-center">
                  <h6 className="m-0">Đơn vị sửa chữa</h6>
                  <div className="mt-2"></div>
                  <input
                    className="form-control"
                    value={bridgeRepair.DonViSuaChua}
                    onChange={(e) => {
                      setBridgeRepair({ ...bridgeRepair, DonViSuaChua: e.target.value });
                    }}
                    placeholder="Nhập đơn vị sửa chữa..."></input>
                </div>

                <div className="d-block mt-2 align-items-center">
                  <h6 className="m-0">Chi phí sửa chữa</h6>
                  <div className="mt-2"></div>
                  <CurrencyInput
                    groupSeparator="."
                    decimalSeparator=","
                    decimalsLimit={2}
                    className=" form-control"
                    prefix="đ"
                    value={bridgeRepair.ChiPhiSuaChua}
                    onValueChange={(value, name) => {
                      setBridgeRepair({ ...bridgeRepair, ChiPhiSuaChua: parseInt(value) });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <DivideHeader expandable={false}>LỊCH SỬ XỬ LÝ</DivideHeader>

            {bridgeRepair.Details.map((item, index) => (
              <div key={item.RepairDetailId}>
                <div className="mt-2"></div>
                <BridgeRepairDetailItem
                  item={item}
                  onDelete={(x) => {
                    deleteDetail(item.Id);
                  }}
                  onChange={(newItem) => {
                    onDetailChanged(item, newItem);
                  }}></BridgeRepairDetailItem>
              </div>
            ))}

            <button className="btn btn-success mt-2" onClick={addDetail}>
              <span className="bi bi-plus-lg text-white me-2"></span>Thêm lịch sử xử lý
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
