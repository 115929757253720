import React, { useEffect, useState } from 'react';

function Clock(props) {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        var intervalInstance = setInterval(() => {
            setTime(new Date());
        }, 1000)

        return function cleanUp(){
            clearInterval(intervalInstance);
        };
    })

    return (
        <div>{time.toLocaleDateString()} - {time.toLocaleTimeString()}</div>
    );
}

export default Clock;
