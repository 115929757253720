import React from 'react'

export default function RefreshButton(props) {
  let text = props.text;
  text = text === '' || text === undefined? "Tải lại dữ liệu" : text;

  return (
    <button className="btn btn-light me-2" onClick={props.onClick}>
        <span className="bi bi-arrow-counterclockwise me-2 text-primary"></span>{text}
    </button>
  )
}

RefreshButton.defaultProps = {
  className: "btn btn-light me-2",
  onClick: () => {},
  text: 'Tải lại dữ liệu'
}
