import React from 'react'
import PropTypes from 'prop-types';

export default function IconButton(props) {
  let cls = `btn ${props.className}`;
  let iconClass = `bi ${props.icon} me-2 text-${props.color}`;
  return (
    <button className={cls} onClick={props.onClick}>
        <span className={iconClass}></span>{props.text}
    </button>
  )
}

IconButton.defaultProps = {
  className: "btn-light",
  onClick: () => {}
}

IconButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
}
