import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import DashboardPage from "./pages/DashboardPage"
import LoginPage from "./pages/Authenticate/LoginPage"
import LogoutPage  from "./pages/Authenticate/LogoutPage"
import BridgeListPage from './pages/Bridge/BridgeListPage'
import BridgesAddPage from './pages/Bridge/BridgeAddPage'
import MapPage from './pages/Map/MapPage'
import BridgeEditPage from './pages/Bridge/BridgeEditPage';
import BridgeRepairListPage from  './pages/BridgeRepair/BridgeRepairListPage';
import BridgeRepairAddPage from  './pages/BridgeRepair/BridgeRepairAddPage';
import BridgeRepairEditPage from  './pages/BridgeRepair/BridgeRepairEditPage';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='' element={<App></App>}>
            <Route path='' element={<MapPage></MapPage>}></Route>
            <Route path='ban-do' element={<MapPage></MapPage>}></Route>
            <Route path='thong-tin-cau' element={<BridgeListPage></BridgeListPage>}></Route>
            <Route path='thong-tin-cau/:id' element={<BridgeEditPage></BridgeEditPage>}></Route>
            <Route path='thong-tin-cau/them' element={<BridgesAddPage></BridgesAddPage>}></Route>

            <Route path='sua-chua-cau' element={<BridgeRepairListPage></BridgeRepairListPage>}></Route>
            <Route path='sua-chua-cau/:id' element={<BridgeRepairEditPage></BridgeRepairEditPage>}></Route>
            <Route path='sua-chua-cau/them' element={<BridgeRepairAddPage></BridgeRepairAddPage>}></Route>
            <Route path='sua-chua-cau/them:bridgeId' element={<BridgeRepairAddPage></BridgeRepairAddPage>}></Route>
            <Route
                path="*"
                element={
                  <main style={{ padding: '1rem' }}>
                    <p>There's nothing here!</p>
                  </main>
                }
              />
        </Route>

        <Route path='/login' element={<LoginPage/>}></Route>  
        <Route path='/logout' element={<LogoutPage/>}></Route>      
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
