import React from 'react'
import { useEffect } from 'react';
import TableHeader from '../../components/common/TableHeader'
import AddButton from '../../components/common/AddButton'
import BackButton from '../../components/common/BackButton';
import { Link } from 'react-router-dom';
import ApiImage from '../../components/common/ApiImage';
import './Bridge.css'
import InputFile from '../../components/common/InputFile';
import { useState } from 'react';
import DivideHeader from '../../components/common/DivideHeader';
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from 'react-leaflet'
import { axiosPost } from '../../ApiService/BaseAPI';
import toastr from 'toastr';
import BridgeModel from './BridgeModel';
import DatePicker from 'react-date-picker';
import CurrencyInput from 'react-currency-input-field';
import InputPrefix from '../../components/common/InputPrefix';
import RefreshButton from '../../components/common/RefreshButton';
import InputFileButton from '../../components/common/InputFileButton';

const defaultLocation = [10.9655, 106.8804]
const defaultZoom = 16
function BridgeAddPage(props) {
    document.title = 'Thêm cầu mới';

    const [newBridge, setNewBridge] = useState(new BridgeModel());

    function resetForm(){
        if (!window.confirm('Bạn có chắc muốn xóa dữ liệu đã nhập?')) return;

        setNewBridge(new BridgeModel());
        toastr.success("Xóa dữ liệu đã nhập thành công!");
    }

    function addButtonClick(){
        if (!window.confirm('Bạn có chắc muốn thêm dữ liệu cầu?')) return;

        axiosPost('bridge', newBridge, (rspns) => {
            if(rspns.IsSuccess){
                toastr.success("Thêm cầu thành công");
            }
        });
    }    

    function anhCauUploaded(rspns){
        setNewBridge({ ...newBridge, HinhAnhCau: rspns.StoredFilePath });
    }

    function anhBinhDoUploaded(rspns){
        setNewBridge({ ...newBridge, HinhAnhBinhDo: rspns.StoredFilePath });
    }

    function anhMatCatUploaded(rspns){
        setNewBridge({ ...newBridge, HinhAnhMatCat: rspns.StoredFilePath });
    }
 
    function LocationMarker(props) {
        const [position, setPosition] = useState(props.position)
        const map = useMapEvents({
          click(e) {
            setPosition(e.latlng)
            setNewBridge({...newBridge, KinhDo: e.latlng.lat, ViDo: e.latlng.lng })
            map.flyTo(e.latlng, defaultZoom)
          },
        })

        useEffect(() => {
            if(props.position != null && props.position !== undefined)
            {
                if(props.position.lat !== 0 && props.position.lng !== 0){
                    setPosition(props.position);
                    map.flyTo(props.position, defaultZoom)
                }
            }
            return () => {
              
            }
        }, [props.position, position])
        
        function markerClick(){
            console.log("Marker clicked")
            console.log(position)
        }
          
        return position === null ? null : (
            <div onClick={markerClick}>
                <Marker position={position}>
                    <Popup>
                        <h6>{newBridge.TenCayCau}</h6>
                        <p>{newBridge.DiaDiem}</p>
                    </Popup>
                </Marker>
            </div>
        )
    }
    
    return (
    <div>
        <TableHeader>
            <div className="d-flex">
                <BackButton></BackButton>
                <div className="spacer"></div>
                <RefreshButton text="Reset dữ liệu nhập" className='btn btn-light me-2' onClick={() => { resetForm(); }}></RefreshButton>
                <AddButton text="Thêm cầu mới" className='btn-success' onClick={addButtonClick}></AddButton>
            </div>
        </TableHeader>
        
        <div className='m-4'>
            <h4>Thêm cầu mới</h4>
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/thong-tin-cau">Thông tin cầu</Link></li>
                <li className="breadcrumb-item" aria-current="page">Thêm cầu mới</li>
            </ol>
            <hr></hr>

            <div className='row'>
                <div className='col-md-4 border-end'>
                    <DivideHeader>HÌNH ẢNH / BÌNH ĐỒ / MẶT CẮT</DivideHeader>
                    <div className='d-flex mt-2'>
                        <h6 className='text-primary'>Hình ảnh cầu</h6>
                        <div className='spacer'></div>
                        <InputFileButton onFileUploaded={anhCauUploaded}></InputFileButton>
                    </div>
                    
                    <ApiImage className='img-bridge mt-2' src={newBridge.HinhAnhCau}></ApiImage>

                    <div className='d-flex mt-4'>
                        <h6 className='text-success'>Ảnh bình đồ</h6>
                        <div className='spacer'></div>
                        <InputFileButton onFileUploaded={anhBinhDoUploaded}></InputFileButton>
                    </div>
                    
                    <ApiImage className='img-bridge mt-2' src={newBridge.HinhAnhBinhDo}></ApiImage>

                    <div className='d-flex mt-4'>
                        <h6 className='text-danger'>Ảnh mặt cắt</h6>
                        <div className='spacer'></div>
                        <InputFileButton onFileUploaded={anhMatCatUploaded}></InputFileButton>
                    </div>
                    
                    <ApiImage className='img-bridge mt-2' src={newBridge.HinhAnhMatCat}></ApiImage>
                </div>

                <div className='col-md-8'>
                    {/* Thông tin chung */}
                    <div>
                        <DivideHeader>THÔNG TIN CHUNG</DivideHeader>
                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Tên cầu</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.TenCayCau} 
                                   onChange={(e) => { setNewBridge({...newBridge, TenCayCau: e.target.value}) }} 
                                   placeholder='Nhập tên cầu...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Loại cầu</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.LoaiCau} 
                                   onChange={(e) => { setNewBridge({...newBridge, LoaiCau: e.target.value}) }} 
                                   placeholder='Nhập loại cầu...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Cấp</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.Cap} 
                                   onChange={(e) => { setNewBridge({...newBridge, Cap: e.target.value}) }} 
                                   placeholder='Nhập cấp cầu...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Lý trình</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.LyTrinh} 
                                   onChange={(e) => { setNewBridge({...newBridge, LyTrinh: e.target.value}) }} 
                                   placeholder='Nhập lý trình cầu...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Tải trọng</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.TaiTrong} 
                                   onChange={(e) => { setNewBridge({...newBridge, TaiTrong: e.target.value}) }} 
                                   placeholder='Nhập tải trọng cầu...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                                <h6 className='m-0'>Chiều dài cầu (m)</h6>
                                <div className='spacer'></div>
                                <input className='form-control w-75-percent' value={newBridge.ChieuDai} 
                                    onChange={(e) => { setNewBridge({...newBridge, ChieuDai: e.target.value}) }} 
                                    placeholder='Nhập chiều dài cầu...'></input>
                            </div>

                            <div className='d-flex mt-2 align-items-center'>
                                <h6 className='m-0'>Chiều rộng cầu (m)</h6>
                                <div className='spacer'></div>
                                <input className='form-control w-75-percent' value={newBridge.ChieuRong} 
                                    onChange={(e) => { setNewBridge({...newBridge, ChieuRong: e.target.value}) }} 
                                    placeholder='Nhập chiều rộng cầu...'></input>
                            </div>
                    </div>

                    {/* Thông tin vị trí */}
                    <div className='mt-4'>
                        <DivideHeader>THÔNG TIN VỊ TRÍ</DivideHeader>
                        <div className='mt-2 align-items-center'>
                            <h6 className='mb-2'>Địa điểm</h6>
                            <textarea className='form-control mb-2' value={newBridge.DiaDiem} rows="2"
                                   onChange={(e) => { setNewBridge({...newBridge, DiaDiem: e.target.value}) }} 
                                   placeholder='Nhập địa điểm cầu...'></textarea>
                        </div>

                        <div className='mt-2 mb-2 align-items-center'>
                            <h6 className='mb-2'>Tạo độ</h6>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="input-group">
                                        <span className="input-group-text">Kinh độ</span>
                                        <input type="number" className="form-control" value={newBridge.KinhDo} 
                                               onChange={(e) => { setNewBridge({...newBridge, KinhDo: e.target.value}); }} ></input>
                                    </div>
                                </div>

                                <div className='col-md-6 ps-0'>
                                <div className="input-group">
                                        <span className="input-group-text">Vĩ độ</span>
                                        <input type="number" className="form-control" value={newBridge.ViDo}
                                        onChange={(e) => { setNewBridge({...newBridge, ViDo: e.target.value}) }} ></input>
                                    </div>
                                </div>
                            </div>
                            

                        </div>

                        <MapContainer center={defaultLocation} zoom={defaultZoom} style={{height: "240px"}} scrollWheelZoom={false}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                            <LocationMarker position={{lat: newBridge.KinhDo, lng: newBridge.ViDo }}></LocationMarker>
                        </MapContainer>
                    </div>

                    {/* Thi công cầu */}
                    <div className='mt-4'>
                        <DivideHeader>THÔNG TIN THI CÔNG</DivideHeader>
                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='mb-2'>Ngày khởi công</h6>
                            <div className='spacer'></div>
                            <DatePicker className='w-75-percent' format='dd/MM/y'
                            onChange={(e) => { setNewBridge({ ...newBridge, NgayKhoiCong: e }); }} value={newBridge.NgayKhoiCong.toDateTime()}></DatePicker>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='mb-2'>Ngày hoàn thành</h6>
                            <div className='spacer'></div>
                            <DatePicker className='w-75-percent' format='dd/MM/y'
                                        onChange={(e) => { setNewBridge({ ...newBridge, NgayHoanThanh: e }); }} value={newBridge.NgayHoanThanh.toDateTime()}></DatePicker>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Chi phí xây dựng</h6>
                            <div className='spacer'></div>
                            <CurrencyInput groupSeparator='.' decimalSeparator=',' decimalsLimit={2}
                                           className='w-75-percent form-control' prefix="đ" value={newBridge.ChiPhiXayDung} 
                                           onValueChange={(value, name) => { setNewBridge({...newBridge, ChiPhiXayDung : value }) }} />
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Chủ đầu tư</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.ChuDauTu}
                                   onChange={(e) => { setNewBridge({...newBridge, ChuDauTu: e.target.value}) }} 
                                   placeholder='Nhập chủ đầu tư...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Đơn vị thiết kế</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.DonViThietKe}
                                   onChange={(e) => { setNewBridge({...newBridge, DonViThietKe: e.target.value}) }} 
                                   placeholder='Nhập đơn vị thiết kế...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Đơn vị thi công</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.DonViThiCong}
                                   onChange={(e) => { setNewBridge({...newBridge, DonViThiCong: e.target.value}) }} 
                                   placeholder='Nhập đơn vị thi công...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Đơn vị giám sát</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.DonViGiamSat}
                                   onChange={(e) => { setNewBridge({...newBridge, DonViGiamSat: e.target.value}) }} 
                                   placeholder='Nhập đơn vị giám sát...'></input>
                        </div>
                    </div>

                    {/* Cấu tạo cầu */}
                    <div className='mt-4'>
                        <DivideHeader>CẤU TẠO CẦU</DivideHeader>
                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Số nhịp</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.SoNhip} type="number"
                                   onChange={(e) => { setNewBridge({...newBridge, SoNhip: e.target.value}) }} 
                                   placeholder='Nhập cấu tạo...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Số mố</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.SoMo}  type="number"
                                   onChange={(e) => { setNewBridge({...newBridge, SoMo: e.target.value}) }} 
                                   placeholder='Nhập loại cầu...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Số trụ</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.SoTru} type="number" 
                                   onChange={(e) => { setNewBridge({...newBridge, SoTru: e.target.value}) }} 
                                   placeholder='Nhập cấp cầu...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Số dầm ngang</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.SoDamNgang} type="number" 
                                onChange={(e) => { setNewBridge({...newBridge, SoDamNgang: e.target.value}) }} 
                                placeholder='Nhập tải trọng cầu...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Số dầm chính</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.SoDamChinh} type="number" 
                                onChange={(e) => { setNewBridge({...newBridge, SoDamChinh: e.target.value}) }} 
                                placeholder='Nhập tải trọng cầu...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Số lan can</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.SoLanCan} type="number" 
                                onChange={(e) => { setNewBridge({...newBridge, SoLanCan: e.target.value}) }} 
                                placeholder='Nhập lý trình cầu...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Số dải phân cách</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.SoDaiPhanCach} type="number" 
                                onChange={(e) => { setNewBridge({...newBridge, SoDaiPhanCach: e.target.value}) }} 
                                placeholder='Nhập tải trọng cầu...'></input>
                        </div>
                    </div>

                    {/* Vật liệu cầu */}
                    <div className='mt-4'>
                        <DivideHeader>VẬT LIỆU CẦU</DivideHeader>
                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Dầm chính</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.DamChinh}
                                   onChange={(e) => { setNewBridge({...newBridge, DamChinh: e.target.value}) }} 
                                   placeholder='Vật liệu dầm chính...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Dầm ngang</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.DamNgang}
                                   onChange={(e) => { setNewBridge({...newBridge, DamNgang: e.target.value}) }} 
                                   placeholder='Vật liệu dầm ngang...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Bán mặt cầu</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.BanMatCau}
                                   onChange={(e) => { setNewBridge({...newBridge, BanMatCau: e.target.value}) }} 
                                   placeholder='Vât liệu bán mặt cầu...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Lan can</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.LanCan}
                                   onChange={(e) => { setNewBridge({...newBridge, LanCan: e.target.value}) }} 
                                   placeholder='Vật liệu lan cầu...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Mố</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.Mo} 
                                   onChange={(e) => { setNewBridge({...newBridge, Mo: e.target.value}) }} 
                                   placeholder='Vật liệu mố...'></input>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Trụ</h6>
                            <div className='spacer'></div>
                            <input className='form-control w-75-percent' value={newBridge.Tru}
                                   onChange={(e) => { setNewBridge({...newBridge, Tru: e.target.value}) }} 
                                   placeholder='Vật liệu trụ...'></input>
                        </div>
                    </div>

                     {/* Kích thước cầu */}
                     <div className='mt-4'>
                        <DivideHeader>KÍCH THƯỚC CẦU</DivideHeader>
                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Chiều dài nhịp</h6>
                            <div className='spacer'></div>
                            <InputPrefix prefix='m' className='form-control w-75-percent' value={newBridge.ChieuDaiNhip}
                                         onChange={(e) => { setNewBridge({...newBridge, ChieuDaiNhip: e}) }} 
                                         placeholder='Nhập chiều dài nhịp...' type='number'></InputPrefix>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Bề rộng xe chạy (m)</h6>
                            <div className='spacer'></div>
                            <InputPrefix prefix='m' className='form-control w-75-percent' value={newBridge.BeRongXeChay}
                                         onChange={(e) => { setNewBridge({...newBridge, BeRongXeChay: e}) }} 
                                         placeholder='Nhập bề rộng xe chạy...' type='number'></InputPrefix>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Khoảng cách dầm chính (m)</h6>
                            <div className='spacer'></div>
                            <InputPrefix prefix='m' className='form-control w-75-percent' value={newBridge.KhoangCachDamChinh}
                                         onChange={(e) => { setNewBridge({...newBridge, KhoangCachDamChinh: e}) }} 
                                         placeholder='Nhập khoảnh cách dầm chính...' type='number'></InputPrefix>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Khoảng cách dầm ngang (m)</h6>
                            <div className='spacer'></div>
                            <InputPrefix prefix='m' className='form-control w-75-percent' value={newBridge.KhoanCachDamNgang}
                                         onChange={(e) => { setNewBridge({...newBridge, KhoanCachDamNgang: e}) }} 
                                         placeholder='Nhập khoảng cách dầm ngang...' type='number'></InputPrefix>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Chiều cao bản mặt cầu (m)</h6>
                            <div className='spacer'></div>

                            <InputPrefix prefix='m' className='form-control w-75-percent' value={newBridge.ChieuCaoBanMatCau}
                                         onChange={(e) => { setNewBridge({...newBridge, ChieuCaoBanMatCau: e}) }} 
                                         placeholder='Nhập chiều cao bản mặt cầu...' type='number'></InputPrefix>
                        </div>

                        <div className='d-flex mt-2 align-items-center'>
                            <h6 className='m-0'>Bề rộng lan can (m)</h6>
                            <div className='spacer'></div>

                            <InputPrefix prefix='m' className='form-control w-75-percent' value={newBridge.BeRongLanCan}
                                         onChange={(e) => { setNewBridge({...newBridge, BeRongLanCan: e}) }} 
                                         placeholder='Nhập bề rộng lan can...' type='number'></InputPrefix>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default BridgeAddPage
