export default function InputPrefix(props) {
    return (
        <div className={props.className + " input-group p-0 border-0"}>
            <span className="input-group-text">{props.prefix}</span>
            <input  className={props.className}
                    type={props.type} placeholder={props.placeholder}
                    value={props.value} onChange={(e) => props.onChange(e.target.value)}/>
        </div>
    )
}
