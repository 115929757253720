import { v4 } from "uuid";

export class BridgeRepairDetailModel {
  constructor() {
    this.Id = v4();
    this.RepairDetailId = 0;
    this.ThoiGian = new Date();
    this.ThoiGianStr = "";
    this.NoiDung = "";
    this.GhiChu = "";
    this.Images = [];
    this.ImageString = "";
  }
}

export class BridgeRepairModel {
  constructor() {
    this.Id = v4();
    this.RepairHistoryId = 0;
    this.BridgeId = 0;
    this.TenCayCau = "";
    this.NgayKiemTra = null;
    this.DonViKiemTra = "";
    this.LoaiHuHong = "";
    this.NgaySuaChua = null;
    this.DonViSuaChua = "";
    this.ChiPhiSuaChua = 0;
    this.Details = [];
    this.Images = [];
  }
}
