import React, { useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
import AddImageButton from '../../components/common/AddImageButton';
import { BridgeRepairDetailModel } from './Models';
import ApiImage from '../../components/common/ApiImage';

export default function RepairDetailItem(props) {
    const [detailRepair, setDetailRepair] = useState(props.item);

    useEffect(()=> {
        props.onChange(detailRepair); 

    }, [detailRepair])

    useEffect(()=> {
        setDetailRepair({...props.item})
    }, [props.item])

    function imgAdded(img){
        let imgs = detailRepair.Images;
        imgs.push(img);
        console.log('file added')
        console.log(img.FileName)
        setDetailRepair({...detailRepair, Images: imgs})
    }

    function removeImg(filePath){
        let imgs = detailRepair.Images;
        imgs = imgs.filter(x => x.StoredFilePath !== filePath);

        setDetailRepair({...detailRepair, Images: imgs});
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='d-flex'>
                            <span className='bi bi-calendar me-2 font-18'></span>
                            <h6 className='m-0'>{detailRepair.ThoiGian.toVnString()}</h6>
                            <div className='spacer'></div>
                            <span onClick={props.onDelete} className='hand bi bi-x text-danger font-24' title='Xóa hoạt động'></span>
                        </div>

                        <hr className='mt-1 mb-2'></hr>
                    </div>

                    <div className='col-xxl-4 col-md-12'>
                        <div className='row'>
                            <div className='form-group col-md-6 col-xxl-12'>
                                <h6 className='mb-2 text-success'>Nội dung</h6>
                                <div className='spacer'></div>
                                <input  className='form-control' value={detailRepair.NoiDung} 
                                        onChange={(e) => {setDetailRepair({...detailRepair, NoiDung: e.target.value})}} 
                                        placeholder='Nhập nội dung xử lý'></input>
                            </div>

                            <div className='form-group mt-xxl-2 col-md-6 col-xxl-12'>
                                <h6 className='mb-2 text-primary'>Thời gian</h6>
                                <div className='spacer'></div>
                                <DatePicker className='w-100' value={detailRepair.ThoiGian.toDateTime()} format='dd/MM/y' onChange={(e) => { setDetailRepair({...detailRepair, ThoiGian: e }) }}></DatePicker>
                            </div>

                            <div className='form-group mt-2'>
                                <h6 className='mb-2'>Ghi chú</h6>
                                <div className='spacer'></div>
                                <textarea className='form-control' value={detailRepair.GhiChu} onChange={(e) => {setDetailRepair({...detailRepair, GhiChu: e.target.value})}} rows={2} placeholder='Nhập nội dung xử lý'></textarea>
                            </div>
                        </div>
                    </div>

                    <div className='col-xxl-8 col-md-12  mt-md-2'>
                        <h6 className='mb-2 text-success'>Hình ảnh hiện trường</h6>
                        <div className='d-flex flex-wrap'>
                            {detailRepair.Images.map((item, index) => 
                                <div key={item.StoredFilePath} className='d-flex flex-column align-items-center me-2'>
                                    <ApiImage src={item.StoredFilePath}></ApiImage>
                                    <div className='d-flex align-items-center w-100'>
                                        <span className='text-wrap spacer wp-150-max'>{item.FileName}</span>
                                        <div className='spacer'></div>
                                        <span className='bi bi-x text-danger hand' onClick={(e) => removeImg(item.StoredFilePath)} style={{fontSize: '24px'}} title='Xóa hình ảnh'></span>
                                    </div>
                                </div>
                            )}
                            <div className='d-flex flex-column align-items-start me-2'>
                                <AddImageButton onFileAdded={imgAdded}></AddImageButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    )
}

RepairDetailItem.prototype = {
    item: BridgeRepairDetailModel.prototype
}
