import React, { useState } from "react";
import TableHeader from "../../components/common/TableHeader";
import BackButton from "../../components/common/BackButton";
import "./Bridge.css";
import RefreshButton from "../../components/common/RefreshButton";
import IconButton from "../../components/common/IconButton";
import BridgeEditComponent from "./BridgeEditComponent";
import { axiosPut } from "../../ApiService/BaseAPI";
import { useParams } from "react-router-dom";
import toastr from "toastr";

function BridgeEditPage(props) {
  const bridgeId = useParams.id;
  const [doUpdate, setDoUpdate] = useState(false);

  function refreshData() {
    if (!window.confirm("Tải lại dữ liệu sẽ làm mất các thay đổi, bạn có chắc muốn tải lại dữ liệu?")) return;

    //loadDataDetail();
    toastr.success("Đã tải lại dữ liệu!");
  }

  function update() {
    setDoUpdate(doUpdate + 1);
  }

  return (
    <div>
      <TableHeader>
        <div className="d-flex">
          <BackButton></BackButton>
          <div className="spacer"></div>
          <RefreshButton text="Tải lại dữ liệu" className="btn btn-light me-2" onClick={refreshData}></RefreshButton>
          <IconButton text="Lưu thay đổi" icon="bi-check-square" className="btn-success" color="white" onClick={update}></IconButton>
        </div>
      </TableHeader>

      <div className="m-3">
        <BridgeEditComponent doUpdate={doUpdate}></BridgeEditComponent>
      </div>
    </div>
  );
}

export default BridgeEditPage;
