import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { _config } from "../../ApiService/BaseAPI";
import { Link } from "react-router-dom";

export default function ApiImage(props) {
  const [src, setSrc] = useState(props.src);
  const [error, setError] = useState(false);

  function onError() {
    if (!error) {
      setSrc(props.fallbackSrc);
      setError(true);
    }
  }

  useEffect(() => {
    if (props.src !== src && props.src !== "" && props.src !== undefined) {
      setSrc(props.src);
    }

    return () => {};
  }, [props.src, src]);

  return (
    <a href={_config.BASE_API_PATH + src} target="_blank" rel="noreferrer">
      <img className={`border ${props.className}`} alt="loi hinh anh" src={_config.BASE_API_PATH + src} onError={onError} style={{ width: props.width, minWidth: props.width, objectFit: "cover", background: "whitesmoke" }} height={props.height} />
    </a>
  );
}

ApiImage.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.any,
  width: PropTypes.any,
};

ApiImage.defaultProps = {
  src: "",
  fallbackSrc: "/placeholder.jpg",
  className: "",
  height: "140px",
  width: "100%",
};
