import React, { useRef, useState } from 'react'
import InputFile from './InputFile';

export default function InputFileButton(props) {
    function onFileUploaded(result) {
        if (props.onFileUploaded !== undefined) {
            props.onFileUploaded(result);
        }
    }

    const [trigger, setTrigger] = useState(true)

    return (
        <div>
            <InputFile className='d-none' trigger={trigger} onFileUploaded={onFileUploaded}></InputFile>
            <div className='d-flex hand' onClick={() => { setTrigger(!trigger) }}>
                <span className='bi bi-cloud-arrow-up text-center text-secondary me-2 hand font-24' title='Thêm hình ảnh'></span>
                Chọn hình
            </div>
        </div>
    )
}
