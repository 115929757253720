import React, { useEffect, useState } from "react";
import TableHeader from "../../components/common/TableHeader";
import InputSearch from "../../components/common/InputSearch";
import { axiosGet } from "../../ApiService/BaseAPI";
import BridgeListItem from "./BridgeListItem";
import toastr from "toastr";
import AddButton from "../../components/common/AddButton";
import { useNavigate } from "react-router-dom";
import RefreshButton from "../../components/common/RefreshButton";
import InputSelectFilter from "../../components/common/InputSelectFilter";
import MDateRangePicker from "../../components/common/MDateRangePicker";
import { fil } from "date-fns/locale";

function BridgesPage(props) {
  document.title = "Thông tin cầu";
  const [bridgesData, setBridgesData] = useState([]);
  //const [bridgeTypes, setBridgeTypes] = useState([]);
  const [filterParams, setFilterParams] = useState({
    keyword: "",
    bridgeTypes: [],
    startKhoiCong: null,
    endKhoiCong: null,
    startHoanThanh: null,
    endHoanThanh: null,
    startXayDung: null,
    endXayDung: null,
  });

  let navigate = useNavigate();

  function loadData() {
    let params = { ...filterParams };
    params.bridgeTypes = JSON.stringify(filterParams.bridgeTypes);
    axiosGet(
      "/bridge",
      params,
      (rspns) => {
        setBridgesData(rspns.Data);
      },
      (error) => {
        toastr.error(error);
      }
    );
  }

  function loadBridgeTypes() {
    axiosGet("loai-cau", {}, function (rspns) {
      if (rspns.IsSuccess) {
        setFilterParams({
          ...filterParams,
          bridgeTypes: rspns.Data.map((x) => {
            return { key: x, value: false };
          }),
        });
      }
    });
  }

  useEffect(() => {
    loadBridgeTypes();
    loadData();
  }, []);

  // useEffect(() => {
  //   loadData();
  // }, [filterParams.keyword]);

  function onCheckTypeChange(newItem, value) {
    let item = filterParams.bridgeTypes.find((x) => x.key === newItem.key);
    item.value = value;
    console.log(item);

    setFilterParams({ ...filterParams, bridgeTypes: filterParams.bridgeTypes });
  }

  function handleSearch(e) {
    let text = e.target.value;
    setFilterParams({ ...filterParams, keyword: text });
    //loadData();
  }

  function addButtonClick() {
    navigate("/thong-tin-cau/them");
  }

  return (
    <div>
      <div className="d-none"></div>

      <div className="page-header p-3 pb-0">
        <div className="row">
          <div className="col">
            <div className="page-pretitle">Thông tin cầu</div>
            <h2 className="page-title">Quản lý thông tin cầu</h2>
          </div>
          <div className="col-auto">
            <div className="btn-list">
              <div>
                <button data-bs-toggle="dropdown" type="button" className="btn btn-primary dropdown-toggle dropdown-unchevron" onClick={addButtonClick}>
                  <i className="ti ti-plus"></i>Thêm cầu
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-body p-3 mt-0">
        <div className="row">
          <div className="col-left">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <div className="subheader">Loại cầu</div>
                  {filterParams.bridgeTypes.map((x) => {
                    return (
                      <label className="form-check" key={x.key}>
                        <input type="checkbox" className="form-check-input" name="product-type" onChange={(e) => onCheckTypeChange(x, e.target.checked)} />
                        <span className="form-check-label">{x.key}</span>
                      </label>
                    );
                  })}
                </div>
                <label className="text-primary subheader">Thời gian khởi công</label>
                <MDateRangePicker onDateChange={(start, end) => setFilterParams({ ...filterParams, startKhoiCong: start, endKhoiCong: end })}></MDateRangePicker>

                <label className="mt-2 text-primary subheader">Thời gian hoàn thành</label>
                <MDateRangePicker onDateChange={(start, end) => setFilterParams({ ...filterParams, startHoanThanh: start, endHoanThanh: end })}></MDateRangePicker>
                <label className="mt-2 text-primary subheader">Thời gian xây dựng</label>
                <MDateRangePicker onDateChange={(start, end) => setFilterParams({ ...filterParams, startXayDung: start, endXayDung: end })}></MDateRangePicker>
                <div className="form-group mt-2">
                  <div className="row">
                    <div className="col-auto">
                      <button className="btn btn-light" onClick={loadData}>
                        <i className="ti ti-refresh"></i>Làm mới
                      </button>
                    </div>
                    <div className="col ps-0">
                      <button className="btn btn-primary btn-block" onClick={loadData}>
                        <i className="ti ti-search"></i>Áp dụng
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-right">
            <div className="card">
              <div className="card-header">
                <div className="row w-100">
                  <div className="col-md-3">
                    <div className="input-icon">
                      <input type="text" className="form-control" placeholder="Tìm tên cầu..." onChange={handleSearch} />
                      <span className="input-icon-addon">
                        <i className="ti ti-search"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div>
                  {bridgesData.map((x) => (
                    <BridgeListItem item={x} key={x.BridgeId} onDelete={loadData}></BridgeListItem>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BridgesPage;
