import React from "react";
import "./Bridge.css";
import IconButton from "../../components/common/IconButton";
import BridgeModel from "./BridgeModel";
import ApiImage from "../../components/common/ApiImage";
import { useNavigate } from "react-router-dom";
import { axiosDelete } from "../../ApiService/BaseAPI";
import toastr from "toastr";

export default function BridgeListItem(props) {
  let bridge = props.item;
  const navigator = useNavigate();

  function navToDetailPage() {
    console.log("navigate to " + bridge.BridgeId);
    navigator(`/thong-tin-cau/${bridge.BridgeId}`);
  }

  function deleteBridge(bridge) {
    if (!window.confirm("Bạn có chắc muốn xóa " + bridge.TenCayCau)) return;

    axiosDelete("bridge/" + bridge.BridgeId, {}, (rspns) => {
      if (rspns.IsSuccess) {
        toastr.success("Đã xóa " + bridge.TenCayCau);
        if (props.onDelete !== undefined) {
          props.onDelete();
        }
      }
    });
  }

  return (
    <div className="card">
      <div className="card-body bridge-list-item">
        <div className="row">
          <div className="col-md-2">
            <ApiImage src={bridge.HinhAnhCau} alt="Hình ảnh cầu"></ApiImage>
          </div>
          <div className="col-md-10">
            <div className="d-flex h-100">
              <div className="px-2 d-flex flex-column h-100 spacer">
                <h3 className="text-black">{bridge.TenCayCau}</h3>

                <div className="row">
                  <div className="col">
                    <p className="mb-1">
                      <b>Địa điểm:</b> <span className={bridge.DiaDiem === "Chưa xác định" ? "text-danger" : ""}>{bridge.DiaDiem}</span>{" "}
                    </p>
                    <p className="mb-1">
                      <b>Lý trình:</b> <span className="text-primary">{bridge.LyTrinh}</span>
                    </p>
                    <p className="mb-1">
                      <b>Loại cầu:</b> <span className="text-success">{bridge.LoaiCau}</span>
                    </p>
                    <p className="mb-1">
                      <b>Chiều dài (m):</b> {bridge.ChieuDai}
                    </p>
                    <p className="mb-1">
                      <b>Chiều rộng (m):</b> {bridge.ChieuRong}
                    </p>
                  </div>

                  <div className="col">
                    <p className="mb-1">
                      <b className="text-primary">Ngày khởi công (m):</b> {bridge.NgayKhoiCong}
                    </p>

                    <p className="mb-1">
                      <b className="text-success">Ngày hoàn thành (m):</b> {bridge.NgayHoanThanh}
                    </p>
                  </div>
                </div>

                <div className="spacer"></div>
              </div>

              <div className="spacer"></div>

              <div className="d-flex flex-column h-100">
                <div className="spacer"></div>
                <div className="d-flex">
                  <div className="spacer"></div>
                  <IconButton onClick={navToDetailPage} text="Chi tiết/Cập nhập" className="me-2" color="info" icon="bi-info-square"></IconButton>

                  <IconButton
                    onClick={() => {
                      deleteBridge(bridge);
                    }}
                    text="Xóa cây cầu"
                    className="me-2"
                    color="danger"
                    icon="bi-x-square"></IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BridgeListItem.propsTypes = {
  item: new BridgeModel(),
};
