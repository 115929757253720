import React from 'react';
import ReactDOM from 'react-dom';

function Modal({ isShowing, hide, title, saveChange, children }){ 
    if(isShowing){
        return ReactDOM.createPortal(
            <React.Fragment>
                <div id="exampleModalLive" className="modal fade show" tabIndex={-1} role="dialog" 
                aria-labelledby="exampleModalLiveLabel" style={{ display: 'flex'}}>
                <div className='spacer'></div>
                    <div className="modal-dialog modal-lg-right" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLiveLabel">{title}</h5>
                            <button type="button" className="btn" data-dismiss="modal" aria-label="Close" onClick={hide}>
                                <span className='bi bi-x font-24'></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={hide}>Đóng</button>
                            <button type="button" className="btn btn-success" data-dismiss="modal" onClick={saveChange}>Lưu</button>
                        </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
          , document.body
        )
    }
    return null;
}

export default Modal;