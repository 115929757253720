export default class BridgeModel{
    constructor(){
        this.HinhAnhCau = "";
        this.HinhAnhBinhDo =  "";
        this.HinhAnhMatCat =  "";
        this.TenCayCau =  "";
        this.LoaiCau =  "";
        this.Cap =  "";
        this.LyTrinh =  "";
        this.TaiTrong =  "";
        this.DiaDiem =  "";
        this.ChieuDai = "";
        this.ChieuRong = "";

        this.SoNhip =  0;
        this.SoMo =  0;
        this.SoTru =  0;
        this.SoDamChinh =  0;
        this.SoDamNgang =  0;
        this.SoLanCan =  0;
        this.SoDaiPhanCach =  0;

        this.DamChinh =  "";
        this.DamNgang =  "";
        this.BanMatCau =  "";
        this.LanCan =  "";
        this.Mo =  "";
        this.Tru =  "";

        this.KinhDo =  0;
        this.ViDo =  0;

        this.NgayKhoiCong = new Date();
        this.NgayHoanThanh = new Date();
        this.ChuDauTu = "";
        this.DonViThietKe = "";
        this.DonViThiCong = "";
        this.DonViGiamSat = "";
        this.ChiPhiXayDung = 0.00;

        this.ChieuDaiNhip = 0;
        this.BeRongXeChay = 0;
        this.KhoangCachDamChinh = 0;
        this.KhoanCachDamNgang = 0;
        this.ChieuCaoBanMatCau = 0;
        this.BeRongLanCan = 0;

        this.BridgeRepairs = [];
    }
}