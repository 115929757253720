import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import React, { useCallback, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { addMonths, format } from "date-fns";
import addDays from "date-fns/addDays";
import * as locales from "react-date-range/dist/locale";
const nameMapper = {
  ar: "Arabic",
  bg: "Bulgarian",
  ca: "Catalan",
  cs: "Czech",
  cy: "Welsh",
  da: "Danish",
  de: "German",
  el: "Greek",
  enGB: "English (United Kingdom)",
  enUS: "English (United States)",
  eo: "Esperanto",
  es: "Spanish",
  et: "Estonian",
  faIR: "Persian",
  fi: "Finnish",
  fil: "Filipino",
  fr: "French",
  hi: "Hindi",
  hr: "Croatian",
  hu: "Hungarian",
  hy: "Armenian",
  id: "Indonesian",
  is: "Icelandic",
  it: "Italian",
  ja: "Japanese",
  ka: "Georgian",
  ko: "Korean",
  lt: "Lithuanian",
  lv: "Latvian",
  mk: "Macedonian",
  nb: "Norwegian Bokmål",
  nl: "Dutch",
  pl: "Polish",
  pt: "Portuguese",
  ro: "Romanian",
  ru: "Russian",
  sk: "Slovak",
  sl: "Slovenian",
  sr: "Serbian",
  sv: "Swedish",
  th: "Thai",
  tr: "Turkish",
  uk: "Ukrainian",
  vi: "Vietnamese",
  zhCN: "Chinese Simplified",
  zhTW: "Chinese Traditional",
};

export default function MDateRangePicker(props) {
  const [dateRange, setDateRange] = useState([
    {
      endDate: new Date(),
      startDate: addMonths(new Date(), -1),
      key: "selection",
    },
  ]);

  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [showModal, setShowModal] = useState(false);

  function toggle() {
    setShowModal(!showModal);
    if (!showModal) {
      onDateRangeChanged(dateRange);
    }
  }

  function onStartDateChange(e) {
    let startDate = e.target.value;
    setStartDate(startDate);

    dateRange[0] = {
      ...dateRange[0],
      startDate: new Date(startDate),
    };
    setDateRange(dateRange);
    onDateRangeChange(startDate, endDate);
  }

  function onEndDateChange(e) {
    let endDate = e.target.value;
    setEndDate(endDate);

    dateRange[0] = {
      ...dateRange[0],
      endDate: new Date(endDate),
    };
    setDateRange(dateRange);
    onDateRangeChange(startDate, endDate);
  }

  function onDateRangeChange(startDate, endDate) {
    props.onDateChange(startDate, endDate);
  }

  function onDateRangeChanged(dateRange) {
    setDateRange(dateRange);

    var startDateStr = format(dateRange[0].startDate, "yyyy-MM-dd");
    setStartDate(startDateStr);

    var endDateStr = format(dateRange[0].endDate, "yyyy-MM-dd");
    setEndDate(endDateStr);

    onDateRangeChange(format(dateRange[0].startDate, "yyyy-MM-dd"), format(dateRange[0].endDate, "yyyy-MM-dd"));
  }

  function resetFilter() {
    setStartDate("");
    setEndDate("");

    onDateRangeChange(null, null);
  }

  const datePicker = (
    <DateRangePicker
      className="date-picker position-relative"
      locale={locales["vi"]}
      onChange={(item) => {
        let dateRange = [item.selection];
        onDateRangeChanged(dateRange);
      }}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={dateRange}
      direction="horizontal"
    />
  );

  return (
    <div style={{ zIndex: 1000 }}>
      <div>
        <div className="d-flex align-items-end">
          <div className="spacer pe-0">
            <label className="">Từ ngày</label>
            <input
              value={startDate}
              type="text"
              className="form-control"
              name="product-type"
              onChange={(e) => {
                onStartDateChange(e);
              }}
            />
          </div>

          <div className="spacer ps-0 me-1">
            <label className="">Đến ngày</label>
            <input
              value={endDate}
              type="text"
              className="form-control"
              name="product-type"
              onChange={(e) => {
                onEndDateChange(e);
              }}
            />
          </div>

          <button className="btn p-0" title="Mở lịch" onClick={toggle}>
            <span className="ti ti-calendar m-0"></span>
          </button>

          <button className="btn p-0" title="Xóa bộ lọc" onClick={resetFilter}>
            <span className="ti ti-eraser m-0"></span>
          </button>
        </div>
      </div>
      {showModal ? datePicker : null}
    </div>
  );
}

MDateRangePicker.defaultProps = {
  startDate: "",
  endDate: "",
  onDateChange: () => {},
};
