import React, { useRef, useState } from 'react'
import { PropTypes } from 'prop-types';

function DivideHeader(props) {
  let iconDefault = props.expanded? 'bi-chevron-down' : 'bi-chevron-left';
  const [icon, setIcon] = useState(iconDefault);
  const [expanded, setExpanded] = useState(props.expanded);
  const expandButton = useRef(null);

  function onExpandIconClick(){
    if(expanded === true){
      setExpanded(false);
      setIcon('bi-chevron-left');
    }

    if(expanded === false){
      setExpanded(true);
      setIcon('bi-chevron-down');
    }
  }
  let badgeClass = `hand bg-${props.color} divide-header`;

  return (
    <div className="w-100 border-bottom d-flex">
        <span className={badgeClass} onClick={() => { expandButton.current.click(); onExpandIconClick(); }}>
            {props.children}
        </span>
        <div className='spacer'></div>
        {props.expandable === true? 
          <div>
            <button className='btn' type="button" onClick={onExpandIconClick} data-toggle="collapse" ref={expandButton}
                    data-target={"#" + props.targetColapse} aria-expanded="true" aria-controls={props.targetColapse}>
              <span className={'bi ' + icon}></span>
            </button>
          </div>: ''
        }
        
    </div>
  )
}

export default DivideHeader

DivideHeader.propType = {
  expanded: PropTypes.bolean,
  expandable: PropTypes.bolean,
  color: PropTypes.string
}

DivideHeader.defaultProps = {
  expanded: false,
  expandable: true,
  color: "primary"
}