import { Link, NavLink } from 'react-router-dom';
import Clock from './Clock'

function AppBar(props) {


  return (
    <div className="header">
        <div className="top-row px-4 bg-white d-flex">
            <Link to='/' className="p-0 me-2 d-flex align-items-center">
              <img className='me-2' src='/images/Logo_Dong_Nai.png' alt='logo' style={{width: '50px', height: '50px', objectFit:'contain'}}></img>
              <h6 className='m-0 me-3 text-black'>CẦU ĐỒNG NAI</h6>
            </Link>
            
            <NavLink to='/ban-do' className="p-0 py-2 me-3 text-muted link">
              <span className='bi bi-geo me-2'></span>
              Bản đồ
            </NavLink>

            <NavLink to='/thong-tin-cau' className="p-0 py-2 me-3 text-muted link">
            <span className='bi bi-card-list me-2'></span>
              Thông tin cầu
            </NavLink>

            <NavLink to='/sua-chua-cau' className="p-0 py-2 me-3 text-muted link">
            <span className='bi bi-gear me-2'></span>
              Kiểm tra/sửa chữa
            </NavLink>

            <div className="spacer"></div>

            <div className="me-4">
                <Clock></Clock>
            </div>

            <div className="d-flex d-none">
                <span>Xin chào</span>

                <a href="/logout" className="ms-4">Logout<span className="bi bi-arrow-right-square ms-2"></span></a>
            </div>
        </div>
    </div>
  )
}

export default AppBar
