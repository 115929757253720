import { Outlet } from 'react-router-dom';
import AppBar from './components/shared/AppBar';
//import { GoogleMap, withScriptjs, withGoogleMap } from 'react-google-maps'

function App() {
  return (
    <div className="d-flex">
      <div className='page'>
          <AppBar></AppBar>
          <div className="main-content position-relative">
              <Outlet></Outlet>
          </div>
      </div>
    </div>
  );
}

export default App;

// const defaultLocation = { lat: 10.9655, lng: 106.8804 }

// function Map(){
//   return <GoogleMap defaultZoom={10} defaultCenter={{defaultLocation}}> </GoogleMap>
// }

// const MapWrapper = withScriptjs(withGoogleMap(Map));


// function App() {
//   return (
//     <div>
//       <MapWrapper googleMapUrl={``}></MapWrapper>
//     </div>
//   );
// }

// export default App;
