import { useEffect, useState } from 'react';

const useModal = () => {
  const [isShowing, setIsShowing] = useState(localStorage.getItem("openModal") === 'true');
  const [isSaveChange, setIsSaveChange] = useState(0);

  function toggle(e) {
      setIsShowing(!isShowing);
      setIsSaveChange(0);
  }

  useEffect(() => {
    if(isShowing){
      localStorage.setItem("openModal", "true");
    }

    if(!isShowing){
      localStorage.setItem("openModal", "false");
    }
  }, [isShowing])

  function saveChanges(){
      setIsSaveChange(isSaveChange + 1);
  }

  return {
    isSaveChange,
    saveChanges,
    isShowing,
    toggle,
  }
};

export default useModal;