import axios from "axios";
import toastr from "toastr";

export const _config = {
  BASE_API_PATH: window.API_PATH,
  API_ROUTE_PATH: window.API_PATH + "api",
};

let token = localStorage.getItem("token");

export const _client = axios.create({
  baseURL: _config.API_ROUTE_PATH,
  headers: { Authorization: `Bearer ${token}` },
});

export function axiosGet(url, data, success, errorHandler1) {
  //console.log("requesting...");
  _client
    .get(url, { params: data })
    .then((response) => {
      //console.log("request ok");
      successHandler(response, success);
    })
    .catch((error) => {
      //console.log("requesting error");
      errorHandler(error, errorHandler1);
    });
}

export function axiosPost(url, data, success, error) {
  _client
    .post(url, data)
    .then((response) => {
      successHandler(response, success);
    })
    .catch((error) => {
      errorHandler(error, error);
    });
}

export function axiosPut(url, data, success, error) {
  _client
    .put(url, data)
    .then((response) => {
      successHandler(response, success);
    })
    .catch((error) => {
      if (error !== undefined) {
        errorHandler(error, error);
      }
    });
}

export function axiosDelete(url, data, success, error) {
  _client
    .delete(url, data)
    .then((response) => {
      successHandler(response, success);
    })
    .catch((error) => {
      errorHandler(error, error);
    });
}

function successHandler(response, handler) {
  let rspns = response.data;
  //console.log(rspns);
  if (rspns.IsSuccess) {
    if (handler !== undefined) {
      handler(rspns);
    }
  } else {
    toastr.error(rspns.Message);
  }
}

function errorHandler(error, handler) {
  if (error !== undefined) {
    console.log(error);
    //toastr.error(error.Message);
    if (handler !== undefined) {
      handler(error);
    }
  }
}
