import React, { useRef, useState, useEffect } from 'react'
import { PropTypes } from 'prop-types';
import { _client } from '../../ApiService/BaseAPI';
import toastr from 'toastr';

export default function InputFile(props) {
    const inputEle = useRef(null);
    function onFileChange(e){
        let file = e.target.files[0];
        if(file === undefined) return;
        uploadFile(file);
    }

    const [renderCount, setRenderCount] = useState(0);

    useEffect(()=>{
        if(renderCount > 0){
            inputEle.current.click();
        }
        setRenderCount(renderCount + 1);
    }, [props.trigger])

    function uploadFile(selectedFile){
        const fileData = new FormData();
        fileData.append("File", selectedFile);

        _client({
            method: 'post',
            url:  'upload/from-react',
            data: fileData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
            var rspns = res.data;
            if(rspns.IsSuccess){
                if(rspns.Data.Uploaded === true){
                    props.onFileUploaded(rspns.Data);
                    inputEle.current.value = '';
                }
                else{
                    toastr.error(rspns.Data.ErrorMessage);
                }
            }
            else{
                toastr.error(rspns.Message);
            }
        }).then((rspns) => {
            if(rspns !== undefined){
                console.log(rspns);
            }
        })
    }

    return (
        <div>
            <input ref={inputEle} className={props.className} type='file' onChange={onFileChange}></input>
        </div>
    )
}

InputFile.propTypes = {
    onFileChange: PropTypes.func,
    onFileUploaded: PropTypes.func.isRequired,
    className: PropTypes.string
}