import React from 'react'
import './Bridge.css'
import IconButton from '../../components/common/IconButton';
import BridgeModel from './BridgeModel';
import ApiImage from '../../components/common/ApiImage';
import { useNavigate } from 'react-router-dom';
import { axiosDelete } from '../../ApiService/BaseAPI';
import toastr from 'toastr';

export default function BridgeListItemSmall(props) {
    let bridge = props.item;
    const navigator = useNavigate();

    function navToDetailPage(){
        console.log('navigate to ' + bridge.BridgeId)
        navigator(`/thong-tin-cau/${bridge.BridgeId}`);
    }

    function deleteBridge(bridge){
        if(!window.confirm('Bạn có chắc muốn xóa ' + bridge.TenCayCau)) return;

        axiosDelete('bridge/' + bridge.BridgeId, {}, (rspns) => {
            if(rspns.IsSuccess){
                toastr.success('Đã xóa ' + bridge.TenCayCau);
                if(props.onDelete !== undefined){
                    props.onDelete();
                }
            }
        })
    }
    const activeClass = props.isActive? "bg-primary card" : "card";
    return (
        <div className={activeClass}>
            <div className='p-2 bridge-list-item '>
                <div className='d-flex'>
                        <ApiImage src={bridge.HinhAnhCau} width="80px" height="50px" alt='Hình ảnh cầu'></ApiImage>
                    <div className='spacer'>
                        <div className='d-flex h-100'>
                            <div className='px-2 d-flex flex-column h-100 spacer hand' onClick={() => props.onItemClick(bridge) }>
                                <b className='text-black'>{bridge.TenCayCau}</b>
                                <p className='mb-1'><span className={bridge.DiaDiem === "Chưa xác định" ? "text-danger" : ""}>{bridge.DiaDiem}</span> </p>
                                {/* 
                                <p className='mb-1'>Lý trình: <span className='text-primary'>{bridge.LyTrinh}</span></p>
                                <p className='mb-1'>Loại cầu: <span className='text-success'>{bridge.LoaiCau}</span></p>
                                <p className='mb-1'>Chiều dài (m): {bridge.ChieuDai}</p>
                                <p className='mb-1'>Chiều rộng (m): {bridge.ChieuRong}</p> */}

                                <div className='spacer'></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

BridgeListItemSmall.propsTypes = {
    item: new BridgeModel()   
}