import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function BackButton(props) {
    const navigate = useNavigate();
    let text = props.text;
    text = text === '' || text === undefined? "Về trang trước" : text;

    function onBackClick(){
        if(props.onClick !== undefined){
            props.onClick();
        }
        navigate(-1);
    }

    return (
        <button className="btn btn-light me-2" onClick={onBackClick}>
            <span className="bi bi-arrow-left me-2 text-primary"></span>{text}
        </button>
    )
}
