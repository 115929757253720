import React from 'react'
import PropTypes from 'prop-types';

export default function AddButton(props) {
  let cls = `btn ${props.className}`;
  return (
    <button className={cls} onClick={props.onClick}>
        <span className="bi bi-plus-square me-2"></span>{props.text}
    </button>
  )
}

AddButton.defaultProps = {
  className: "btn-light",
  onClick: () => {}
}

AddButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
}
