import React, { memo, useCallback, useEffect, useMemo } from "react";
import ReactDOM from "react-dom";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents, useMap } from "react-leaflet";
import { useState, useRef } from "react";
import { axiosGet } from "../../ApiService/BaseAPI";
import toastr from "toastr";
import BridgeInfoModal from "./BridgeInfoModal";
import ApiImage from "../../components/common/ApiImage";
import { Link } from "react-router-dom";
import useModal from "../../components/common/useModal";
import Modal from "../../components/common/Modal";
import BridgeEditComponent from "../Bridge/BridgeEditComponent";
import InputSearch from "../../components/common/InputSearch";
import IconButton from "../../components/common/IconButton";
import BridgeListItemSmall from "../Bridge/BridgeListItemSmall";
import { marker } from "leaflet";
import { useNavigate } from "react-router-dom";
const defaultLocation = [10.9655, 106.8804];

function MapPage(props) {
  document.title = "Bản đồ";

  const { isShowing, isSaveChange, saveChanges, toggle } = useModal();
  const [bridgesData, setBridgesData] = useState([]);
  const [selectedBridgeId, setSelectedBridgeId] = useState(0);
  const [selectedBridge, setSelectedBridge] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [allowZoom, setAllowZoom] = useState(true);
  const [filterParams, setFilterParams] = useState({ keyword: "", type: "" });
  const navigate = useNavigate();
  const mapTypes = [
    {
      attribution: `&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    },
    {
      attribution: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
      url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    },
  ];
  const savedMapType = parseInt(localStorage.getItem("map_type") ?? 0);
  const mapType = mapTypes[savedMapType];

  function selectedBridgeChanged(bridge) {
    localStorage.setItem("sltBridgeId", bridge?.BridgeId ?? 0);
    //setSelectedBridgeId(bridge?.BridgeId??0);
    setSelectedBridge(bridge);
  }

  const loadData = useCallback(() => {
    axiosGet(
      "/bridge",
      filterParams,
      (rspns) => {
        setBridgesData(rspns.Data);
        let sltBrigeId = localStorage.getItem("sltBridgeId") ?? 0;
        setSelectedBridgeId(sltBrigeId);

        if (selectedBridge !== 0) {
          let selectedBridge = rspns.Data.find((x) => x.BridgeId === parseInt(sltBrigeId));
          //console.log(selectedBridge);

          if (selectedBridge !== undefined && selectedBridge != null) {
            setSelectedBridge(selectedBridge);
          }
        }
      },
      (error) => {
        toastr.error(error.message);
      }
    );
  }, [filterParams]);

  //Load data khi mới mở trang
  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSearch = useCallback(
    (e) => {
      setFilterParams({ ...filterParams, keyword: e });
    },
    [filterParams]
  );

  const overlapPanel = useMemo(() => {
    return <OverlapPanel onSearch={handleSearch} keyword={filterParams.keyword} activeBridge={selectedBridge} bridgesData={bridgesData} showDetail={showDetail}></OverlapPanel>;
  }, [bridgesData, selectedBridge, showDetail, handleSearch, filterParams.keyword]);

  function OverlapPanel(props) {
    const [activeBridge, setActiveBridges] = useState(props.activeBridge);
    const [keyword, setKeyword] = useState(props.keyword);
    const [dropdown, setDropDown] = useState(false);

    const map = useMap();
    if (activeBridge != null && activeBridge !== undefined) {
      map.flyTo([activeBridge.KinhDo, activeBridge.ViDo]);
    }

    function itemClick(bridge) {
      map.flyTo({ lat: bridge.KinhDo, lng: bridge.ViDo });
      if (activeBridge !== null && activeBridge !== undefined && activeBridge.BridgeId === bridge.BridgeId) {
        setActiveBridges(null);
        //setSelectedBridge(null)
      } else {
        setActiveBridges(bridge);
        //setSelectedBridge(bridge)
      }
      //map.setZoom(17);
    }

    function BridgeDetailPanel(detailProps) {
      if (detailProps.showDetail) {
        return (
          activeBridge && (
            <div className="card ms-2 p-2">
              <div className="d-flex p-2 border-bottom mb-2">
                <h5 className="m-0">Thông tin cầu</h5>
                <div className="spacer"></div>
                <button
                  className="btn"
                  onClick={() => {
                    setShowDetail(false);
                  }}>
                  <span className="bi bi-x-circle font-24"></span>
                </button>
              </div>
              <div className="detail-bridge-map">
                <BridgeEditComponent bridgeId={detailProps.activeBridge.BridgeId}></BridgeEditComponent>
              </div>
            </div>
          )
        );
      }

      return null;
    }

    const bridgeDetail = useMemo(() => {
      return <BridgeDetailPanel activeBridge={activeBridge} showDetail={props.showDetail}></BridgeDetailPanel>;
    }, [props.showDetail, activeBridge]);

    const ListItem = memo((props) => {
      return (
        <BridgeListItemSmall
          isActive={props.bridge.BridgeId === props.activeBridge?.BridgeId}
          item={props.bridge}
          onItemClick={(e) => {
            props.onItemClick(e);
          }}></BridgeListItemSmall>
      );
    });

    function BridgeList(listProps) {
      const onChange = useCallback((bridge) => {
        map.flyTo({ lat: bridge.KinhDo, lng: bridge.ViDo });
        if (listProps.activeBridge !== null && listProps.activeBridge !== undefined && listProps.activeBridge.BridgeId === bridge.BridgeId) {
          setActiveBridges(null);
          setSelectedBridge(null);
        } else {
          setActiveBridges(bridge);
          setSelectedBridge(bridge);
        }
        //map.setZoom(17);
      }, []);

      return listProps.bridgesData.map((x) => {
        return <ListItem key={x.BridgeId} activeBridge={listProps.activeBridge} bridge={x} onItemClick={onChange}></ListItem>;
      });
    }

    const bridgeList = useMemo(() => {
      return <BridgeList bridgesData={props.bridgesData} activeBridge={activeBridge}></BridgeList>;
    }, [props.bridgesData]);

    function onTextChange(e) {
      setKeyword(e.target.value);
      //loadData(e.target.value)
    }

    function mapTypeChange(type) {
      console.warn("map change");
      localStorage.setItem("map_type", type);
      navigate(0);
    }

    return ReactDOM.createPortal(
      <React.Fragment>
        <div className="">
          <div className="map-overlap">
            <div className="d-flex">
              <div className="left-map-panel spacer">
                <div className="d-block">
                  <div className=" card">
                    <div className="d-flex">
                      <input
                        className="form-control border-0"
                        placeholder="Tìm tên cầu..."
                        key="input-search-key"
                        value={keyword}
                        onChange={(e) => {
                          onTextChange(e);
                        }}></input>
                      <button
                        className="btn"
                        onClick={() => {
                          props.onSearch(keyword);
                        }}>
                        <span className="bi bi-search"></span>
                      </button>

                      <button className="btn" onClick={() => setDropDown(!dropdown)}>
                        <span className="text-primary bi bi-layers"></span>
                      </button>
                      <div className="dropdown">
                        <ul className={dropdown ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenuButton1">
                          <li onClick={() => mapTypeChange(0)}>
                            <span className="dropdown-item hand">Bản đồ giao thông</span>
                          </li>
                          <li
                            onClick={() => {
                              mapTypeChange(1);
                            }}>
                            <span className="dropdown-item hand">Bản đồ vệ tinh</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="card border-radius-none mt-2 list-bridge-map">{bridgeList}</div>
                </div>
              </div>
              <div>{bridgeDetail}</div>
            </div>
          </div>
        </div>
      </React.Fragment>,
      document.body
    );
  }

  const popup = useMemo(() => {
    return (
      selectedBridge && (
        <Popup position={[selectedBridge.KinhDo + 0.0002, selectedBridge.ViDo]} closeButton={false}>
          <div
            className="hand"
            onClick={() => {
              setShowDetail(true);
              selectedBridgeChanged(selectedBridge);
            }}>
            <h6 className="m-0">{selectedBridge.TenCayCau}</h6>
            {selectedBridge.DiaDiem}
          </div>
          <ApiImage src={selectedBridge.HinhAnhCau} height={120}></ApiImage>
          {/* <div className='mt-2'></div> */}
          <div className="d-flex">
            <div className="spacer"></div>
            <button
              onClick={() => {
                setShowDetail(false);
                selectedBridgeChanged(null);
              }}
              className="btn btn-block hand text-danger">
              <span className="bi bi-x-square text-danger me-2"></span>
              Đóng
            </button>
            <button
              onClick={() => {
                setShowDetail(true);
                selectedBridgeChanged(selectedBridge);
              }}
              className="btn btn-block hand text-primary">
              <span className="bi bi-info-square text-primary me-2"></span>
              Chi tiết
            </button>
          </div>
        </Popup>
      )
    );
  }, [selectedBridge]);

  return (
    <div>
      <Modal title="Chi tiết cầu" isShowing={isShowing} hide={toggle} saveChange={saveChanges}>
        <BridgeEditComponent bridgeId={selectedBridgeId} doUpdate={isSaveChange}></BridgeEditComponent>
      </Modal>
      <div className="">
        <div className="w-100">
          <MapContainer center={defaultLocation} zoom={13} style={{ height: "calc(100vh - 60px)" }} zoomControl={false} scrollWheelZoom={true}>
            <TileLayer attribution={mapType.attribution} url={mapType.url} />
            {overlapPanel}

            {bridgesData.map((x) => (
              <Marker
                key={x.KinhDo}
                position={{ lat: x.KinhDo, lng: x.ViDo }}
                eventHandlers={{
                  click: (e) => {
                    selectedBridgeChanged(x);
                    console.log(x);
                  },
                }}></Marker>
            ))}

            {popup}
          </MapContainer>
        </div>
      </div>
    </div>
  );
}

export default MapPage;
