import React from "react";
import TableHeader from "../../components/common/TableHeader";
import AddButton from "../../components/common/AddButton";
import RefreshButton from "../../components/common/RefreshButton";
import InputSearch from "../../components/common/InputSearch";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { axiosDelete, axiosGet } from "../../ApiService/BaseAPI";
import toastr from "toastr";
import BridgeRepairItem from "./BridgeRepairItem";
import IconButton from "../../components/common/IconButton";
import MDateRangePicker from "../../components/common/MDateRangePicker";

export default function BridgeRepairListPage(props) {
  document.title = "Sửa chữa/kiểm tra cầu";
  const [bridgeRepairList, setBridgeRepairList] = useState([]);
  const [bridgeList, setBridgeList] = useState([]);
  function loadBridgeList() {
    axiosGet("bridge/all", {}, function (rspns) {
      setBridgeList(rspns.Data);
    });
  }

  const [filterParams, setFilterParams] = useState({
    keyword: "",
    status: 0,
    startSuaChua: "",
    endSuaChua: "",
    bridgeId: 0,
  });
  let navigate = useNavigate();

  function loadData() {
    console.log("geting bridges..." + filterParams.keyword);
    axiosGet(
      "/bridgeRepair",
      filterParams,
      (rspns) => {
        setBridgeRepairList(rspns.Data);
      },
      (error) => {
        toastr.error(error.message);
      }
    );
  }

  useEffect(() => {
    loadBridgeList();
    loadData();
  }, []);

  //   useEffect(() => {
  //     loadData();
  //   }, [filterParams.keyword]);

  function handleSearch(e) {
    setFilterParams({ ...filterParams, keyword: e.target.value });
    //loadData();
  }

  function onBridgeChanged(e) {
    setFilterParams({ ...filterParams, bridgeId: e.target.value });
  }

  function onStatusChanged(e) {
    console.log(e.target.value);
    setFilterParams({ ...filterParams, status: e.target.value });
  }

  function addButtonClick() {
    navigate("/sua-chua-cau/them");
  }

  function showDetail(id) {
    navigate("/sua-chua-cau/" + id);
  }

  function deleteRepair(id) {
    if (!window.confirm("Bạn có chắc muốn xóa dữ liệu?")) return;

    axiosDelete("bridgerepair/" + id, {}, function (rspns) {
      if (rspns.IsSuccess) {
        toastr.success("Đã xóa dữ liệu");
        return <div></div>;
      }
    });
  }

  return (
    <div>
      {/* <TableHeader>
                <div className="d-flex">
                    <AddButton text="Thêm thông tin sửa chữa" onClick={addButtonClick} className="btn-light"></AddButton>
                    <div className="spacer"></div>
                    <RefreshButton onClick={loadData}></RefreshButton>
                </div>

                <div className='d-flex'>
                    <div className="wp-350 me-4">
                        <InputSearch onTextChange={handleSearch} placeholder="Tìm theo tên cầu..."></InputSearch>
                    </div>
                </div>
            </TableHeader> */}
      <div className="page-header p-3 pb-0">
        <div className="row">
          <div className="col">
            <div className="page-pretitle">Kiểm tra/sữa chữa</div>
            <h2 className="page-title">Quản lý thông tin kiểm tra/sữa chữa</h2>
          </div>
          <div className="col-auto">
            <div className="btn-list">
              <div>
                <button data-bs-toggle="dropdown" type="button" className="btn btn-primary dropdown-toggle dropdown-unchevron" onClick={addButtonClick}>
                  <i className="ti ti-plus"></i>Thêm thông tin sữa chữa
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body p-3 mt-0">
        <div className="row">
          <div className="col-left">
            <div className="card">
              <div className="card-body">
                <div className="form-group">
                  <div className="subheader">Cây cầu</div>
                  <select onChange={onBridgeChanged} className="form-control">
                    <option value={0}>---Tất cả---</option>
                    {bridgeList.map((x) => (
                      <option key={x.BridgeId} value={x.BridgeId}>
                        {x.TenCayCau}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <div className="subheader">Trạng thái</div>
                  <select onChange={onStatusChanged} className="form-control">
                    <option value={0}>---Tất cả---</option>
                    <option value={1}>Chưa sửa</option>
                    <option value={2}>Đã sửa</option>
                  </select>
                </div>
                <label className="text-primary subheader">Thời gian kiểm tra</label>
                <MDateRangePicker onDateChange={(start, end) => setFilterParams({ ...filterParams, startSuaChua: start, endSuaChua: end })}></MDateRangePicker>

                <div className="form-group mt-2">
                  <div className="row">
                    <div className="col-auto">
                      <button className="btn btn-light" onClick={loadData}>
                        <i className="ti ti-refresh"></i>Làm mới
                      </button>
                    </div>
                    <div className="col ps-0">
                      <button className="btn btn-primary btn-block" onClick={loadData}>
                        <i className="ti ti-search"></i>Áp dụng
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-right">
            <div className="card">
              <div className="card-header">
                <div className="row w-100">
                  <div className="col-md-3">
                    <div className="input-icon">
                      <input type="text" className="form-control" placeholder="Tìm nội dung sửa chữa..." onChange={handleSearch} />
                      <span className="input-icon-addon">
                        <i className="ti ti-search"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="">
                  <div className="card">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Tên cây cầu</th>
                          <th>Ngày kiểm tra</th>
                          <th>Đơn vị kiểm tra</th>
                          <th>Loại hư hỏng</th>
                          <th>Trạng thái</th>
                          <th>Ngày sửa chữa</th>
                          <th>Đơn vị sửa chữa</th>
                          <th>Chi phí sửa chữa</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        {bridgeRepairList.map((x) => (
                          <tr key={x.RepairHistoryId}>
                            <td>{x.TenCayCau}</td>
                            <td>{x.NgayKiemTra}</td>
                            <td>{x.DonViKiemTra}</td>
                            <td>{x.LoaiHuHong}</td>
                            <td>{x.TrangThai}</td>
                            <td>{x.NgaySuaChua}</td>
                            <td>{x.DonViSuaChua}</td>
                            <td>{x.ChiPhiSuaChua}</td>
                            <td className="p-0 autosize-column">
                              <div className="d-flex">
                                <button className="btn btn-light" onClick={() => showDetail(x.RepairHistoryId)}>
                                  <span className="bi bi-info-square text-primary me-2"></span>Chi tiết
                                </button>
                                <button className="btn btn-light" onClick={() => deleteRepair(x.RepairHistoryId)}>
                                  <span className="bi bi-x-square text-danger me-2"></span>Xóa
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
