import React from "react";
import { useEffect } from "react";
import TableHeader from "../../components/common/TableHeader";
import AddButton from "../../components/common/AddButton";
import BackButton from "../../components/common/BackButton";
import { Link, useParams } from "react-router-dom";
import ApiImage from "../../components/common/ApiImage";
import "./Bridge.css";
import InputFile from "../../components/common/InputFile";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DivideHeader from "../../components/common/DivideHeader";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import { axiosGet, axiosPut, axiosDelete } from "../../ApiService/BaseAPI";
import toastr from "toastr";
import BridgeModel from "./BridgeModel";
import DatePicker from "react-date-picker";
import CurrencyInput from "react-currency-input-field";
import InputPrefix from "../../components/common/InputPrefix";
import RefreshButton from "../../components/common/RefreshButton";
import IconButton from "../../components/common/IconButton";
import InputFileButton from "../../components/common/InputFileButton";

const defaultLocation = [10.9655, 106.8804];
const defaultZoom = 17;

function BridgeEditComponent(props) {
  const [bridge, setBridge] = useState(new BridgeModel());
  //const [doUpdate, setDoUpdate] = useState(props.doUpdate);
  const pathParams = useParams();
  let bridgeId = 0;

  bridgeId = pathParams.id;
  if (bridgeId === undefined || bridgeId == null || bridgeId === 0) {
    bridgeId = props.bridgeId;
  }

  document.title = "Thông tin cầu";
  const navigate = useNavigate();

  useEffect(() => {
    loadDataDetail();
  }, []);

  useEffect(() => {
    // if(bridgeId === 0 || bridgeId == null || bridgeId === undefined) return;
    // axiosGet('bridge/' + bridgeId, {}, (rspns) => {
    //     setBridge(rspns.Data);
    //     document.title = rspns.Data.TenCayCau;
    // })
    loadDataDetail();
  }, [props.bridgeId]);

  useEffect(() => {
    if (props.doUpdate > 0) {
      if (!window.confirm("Bạn có chắc muốn lưu thay đổi?")) return;

      axiosPut(`bridge/${bridgeId}`, bridge, (rspns) => {
        if (rspns.IsSuccess) {
          toastr.success("Lưu thành công");
          //setDoUpdate(true);
        }
      });
    }
  }, [props.doUpdate]);

  function loadDataDetail() {
    if (bridgeId === 0 || bridgeId == null || bridgeId === undefined) return;

    axiosGet("bridge/" + bridgeId, {}, (rspns) => {
      setBridge(rspns.Data);
      document.title = rspns.Data.TenCayCau;
    });
  }

  function anhCauUploaded(rspns) {
    setBridge({ ...bridge, HinhAnhCau: rspns.StoredFilePath });
  }

  function anhBinhDoUploaded(rspns) {
    setBridge({ ...bridge, HinhAnhBinhDo: rspns.StoredFilePath });
  }

  function anhMatCatUploaded(rspns) {
    setBridge({ ...bridge, HinhAnhMatCat: rspns.StoredFilePath });
  }

  function showDetail(id) {
    navigate("/sua-chua-cau/" + id);
  }

  function deleteRepair(id) {
    if (!window.confirm("Bạn có chắc muốn xóa dữ liệu?")) return;

    axiosDelete("bridgerepair/" + id, {}, function (rspns) {
      if (rspns.IsSuccess) {
        toastr.success("Đã xóa dữ liệu");
        return <div></div>;
      }
    });
  }

  function addKiemTraSuaChua() {
    navigate("/sua-chua-cau/them" + bridgeId);
  }

  function LocationMarker(props) {
    const [position, setPosition] = useState(props.position);
    const map = useMapEvents({
      click(e) {
        setPosition(e.latlng);
        setBridge({ ...bridge, KinhDo: e.latlng.lat, ViDo: e.latlng.lng });
        map.flyTo(e.latlng, defaultZoom);
      },
    });

    useEffect(() => {
      if (props.position != null && props.position !== undefined) {
        if (props.position.lat !== 0 && props.position.lng !== 0) {
          setPosition(props.position);
          map.flyTo(props.position, defaultZoom);
        }
      }
      return () => {};
    }, [props.position, position]);

    function markerClick() {
      console.log("Marker clicked");
      console.log(position);
    }

    return position === null ? null : (
      <div onClick={markerClick}>
        <Marker position={position}>
          <Popup>
            <h6>{bridge.TenCayCau}</h6>
            <p>{bridge.DiaDiem}</p>
          </Popup>
        </Marker>
      </div>
    );
  }

  return (
    <div>
      <div>
        <h1 className="mb-1">{bridge.TenCayCau}</h1>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/thong-tin-cau">Thông tin cầu</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            {bridge.TenCayCau}
          </li>
        </ol>
        <hr className="my-3"></hr>
        <div className="row">
          <div className="col-md-4 border-end">
            <DivideHeader expandable={false}>HÌNH ẢNH / BÌNH ĐỒ / MẶT CẮT</DivideHeader>
            <div className="d-flex mt-2">
              <h6 className="text-primary m-0">Hình ảnh cầu</h6>
              <div className="spacer"></div>
              {props.isEditing ? <InputFileButton onFileUploaded={anhCauUploaded}></InputFileButton> : null}
            </div>

            <ApiImage className="img-bridge mt-2" src={bridge.HinhAnhCau}></ApiImage>

            <div className="d-flex mt-4">
              <h6 className="text-success m-0">Ảnh bình đồ</h6>
              <div className="spacer"></div>
              {props.isEditing ? <InputFileButton onFileUploaded={anhBinhDoUploaded}></InputFileButton> : null}
            </div>

            <ApiImage className="img-bridge mt-2" src={bridge.HinhAnhBinhDo}></ApiImage>

            <div className="d-flex mt-4">
              <h6 className="text-danger m-0">Ảnh mặt cắt</h6>
              <div className="spacer"></div>
              {props.isEditing ? <InputFileButton onFileUploaded={anhMatCatUploaded}></InputFileButton> : null}
            </div>

            <ApiImage className="img-bridge mt-2" src={bridge.HinhAnhMatCat}></ApiImage>
          </div>

          <div className="col-md-8">
            {/* Thông tin chung */}
            <div>
              <DivideHeader targetColapse="div-thong-tin-chung" expanded={true}>
                THÔNG TIN CẦU
              </DivideHeader>
              <div className="collapse show" id="div-thong-tin-chung">
                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Tên cầu</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.TenCayCau}
                    onChange={(e) => {
                      setBridge({ ...bridge, TenCayCau: e.target.value });
                    }}
                    placeholder="Nhập tên cầu..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Loại cầu</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.LoaiCau}
                    onChange={(e) => {
                      setBridge({ ...bridge, LoaiCau: e.target.value });
                    }}
                    placeholder="Nhập loại cầu..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Cấp</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.Cap}
                    onChange={(e) => {
                      setBridge({ ...bridge, Cap: e.target.value });
                    }}
                    placeholder="Nhập cấp cầu..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Lý trình</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.LyTrinh}
                    onChange={(e) => {
                      setBridge({ ...bridge, LyTrinh: e.target.value });
                    }}
                    placeholder="Nhập lý trình cầu..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Tải trọng</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.TaiTrong}
                    onChange={(e) => {
                      setBridge({ ...bridge, TaiTrong: e.target.value });
                    }}
                    placeholder="Nhập tải trọng cầu..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Chiều dài cầu (m)</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.ChieuDai}
                    onChange={(e) => {
                      setBridge({ ...bridge, ChieuDai: e.target.value });
                    }}
                    placeholder="Nhập chiều dài cầu..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Chiều rộng cầu (m)</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.ChieuRong}
                    onChange={(e) => {
                      setBridge({ ...bridge, ChieuRong: e.target.value });
                    }}
                    placeholder="Nhập chiều rộng cầu..."></input>
                </div>
              </div>
            </div>

            {/* Thông tin vị trí */}
            <div className="mt-4">
              <DivideHeader targetColapse="div-vi-tri" expanded={true}>
                VỊ TRÍ CẦU
              </DivideHeader>
              <div id="div-vi-tri" className="collapse show">
                <div className="mt-2 align-items-center">
                  <h6 className="mb-2">Địa điểm</h6>
                  <textarea
                    className="form-control mb-2"
                    value={bridge.DiaDiem}
                    rows="2"
                    onChange={(e) => {
                      setBridge({ ...bridge, DiaDiem: e.target.value });
                    }}
                    placeholder="Nhập địa điểm cầu..."></textarea>
                </div>

                <div className="mt-2 mb-2 align-items-center">
                  <h6 className="mb-2">Tạo độ</h6>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group">
                        <span className="input-group-text">Kinh độ</span>
                        <input
                          type="number"
                          className="form-control"
                          value={bridge.KinhDo}
                          onChange={(e) => {
                            setBridge({ ...bridge, KinhDo: e.target.value });
                          }}></input>
                      </div>
                    </div>

                    <div className="col-md-6 ps-0">
                      <div className="input-group">
                        <span className="input-group-text">Vĩ độ</span>
                        <input
                          type="number"
                          className="form-control"
                          value={bridge.ViDo}
                          onChange={(e) => {
                            setBridge({ ...bridge, ViDo: e.target.value });
                          }}></input>
                      </div>
                    </div>
                  </div>
                </div>

                <MapContainer center={defaultLocation} zoom={defaultZoom} style={{ height: "240px" }} scrollWheelZoom={false}>
                  <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  <LocationMarker position={{ lat: bridge.KinhDo, lng: bridge.ViDo }}></LocationMarker>
                </MapContainer>
              </div>
            </div>

            {/* Thi công cầu */}
            <div className="mt-4">
              <DivideHeader targetColapse="div-thi-cong" expanded={true}>
                THI CÔNG CẦU
              </DivideHeader>
              <div id="div-thi-cong" className="collapse show">
                <div className="d-flex mt-2 align-items-center">
                  <h6 className="mb-2">Ngày khởi công</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    type="date"
                    onChange={(e) => {
                      setBridge({ ...bridge, NgayKhoiCong: e.target.value });
                    }}
                    value={bridge.NgayKhoiCong}></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="mb-2">Ngày hoàn thành</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    type="date"
                    onChange={(e) => {
                      setBridge({ ...bridge, NgayHoanThanh: e.target.value });
                    }}
                    value={bridge.NgayHoanThanh}></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Chi phí xây dựng</h6>
                  <div className="spacer"></div>
                  <CurrencyInput
                    groupSeparator="."
                    decimalSeparator=","
                    decimalsLimit={2}
                    className="w-75-percent form-control"
                    prefix="đ"
                    value={bridge.ChiPhiXayDung}
                    onValueChange={(value, name) => {
                      setBridge({ ...bridge, ChiPhiXayDung: value });
                    }}
                  />
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Chủ đầu tư</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.ChuDauTu}
                    onChange={(e) => {
                      setBridge({ ...bridge, ChuDauTu: e.target.value });
                    }}
                    placeholder="Nhập chủ đầu tư..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Đơn vị thiết kế</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.DonViThietKe}
                    onChange={(e) => {
                      setBridge({ ...bridge, DonViThietKe: e.target.value });
                    }}
                    placeholder="Nhập đơn vị thiết kế..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Đơn vị thi công</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.DonViThiCong}
                    onChange={(e) => {
                      setBridge({ ...bridge, DonViThiCong: e.target.value });
                    }}
                    placeholder="Nhập đơn vị thi công..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Đơn vị giám sát</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.DonViGiamSat}
                    onChange={(e) => {
                      setBridge({ ...bridge, DonViGiamSat: e.target.value });
                    }}
                    placeholder="Nhập đơn vị giám sát..."></input>
                </div>
              </div>
            </div>

            {/* Cấu tạo cầu */}
            <div className="mt-4">
              <DivideHeader targetColapse="div-cau-tao" expanded={true}>
                CẤU TẠO CẦU
              </DivideHeader>
              <div id="div-cau-tao" className="collapse show">
                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Số nhịp</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.SoNhip}
                    type="number"
                    onChange={(e) => {
                      setBridge({ ...bridge, SoNhip: e.target.value });
                    }}
                    placeholder="Nhập cấu tạo..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Số mố</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.SoMo}
                    type="number"
                    onChange={(e) => {
                      setBridge({ ...bridge, SoMo: e.target.value });
                    }}
                    placeholder="Nhập loại cầu..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Số trụ</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.SoTru}
                    type="number"
                    onChange={(e) => {
                      setBridge({ ...bridge, SoTru: e.target.value });
                    }}
                    placeholder="Nhập cấp cầu..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Số dầm ngang</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.SoDamNgang}
                    type="number"
                    onChange={(e) => {
                      setBridge({ ...bridge, SoDamNgang: e.target.value });
                    }}
                    placeholder="Nhập tải trọng cầu..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Số dầm chính</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.SoDamChinh}
                    type="number"
                    onChange={(e) => {
                      setBridge({ ...bridge, SoDamChinh: e.target.value });
                    }}
                    placeholder="Nhập tải trọng cầu..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Số lan can</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.SoLanCan}
                    type="number"
                    onChange={(e) => {
                      setBridge({ ...bridge, SoLanCan: e.target.value });
                    }}
                    placeholder="Nhập lý trình cầu..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Số dải phân cách</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.SoDaiPhanCach}
                    type="number"
                    onChange={(e) => {
                      setBridge({ ...bridge, SoDaiPhanCach: e.target.value });
                    }}
                    placeholder="Nhập tải trọng cầu..."></input>
                </div>
              </div>
            </div>

            {/* Vật liệu cầu */}
            <div className="mt-4">
              <DivideHeader targetColapse="div-vat-lieu" expanded={true}>
                VẬT LIỆU CẦU
              </DivideHeader>
              <div id="div-vat-lieu" className="collapse show">
                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Dầm chính</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.DamChinh}
                    onChange={(e) => {
                      setBridge({ ...bridge, DamChinh: e.target.value });
                    }}
                    placeholder="Vật liệu dầm chính..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Dầm ngang</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.DamNgang}
                    onChange={(e) => {
                      setBridge({ ...bridge, DamNgang: e.target.value });
                    }}
                    placeholder="Vật liệu dầm ngang..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Bán mặt cầu</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.BanMatCau}
                    onChange={(e) => {
                      setBridge({ ...bridge, BanMatCau: e.target.value });
                    }}
                    placeholder="Vât liệu bán mặt cầu..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Lan can</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.LanCan}
                    onChange={(e) => {
                      setBridge({ ...bridge, LanCan: e.target.value });
                    }}
                    placeholder="Vật liệu lan cầu..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Mố</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.Mo}
                    onChange={(e) => {
                      setBridge({ ...bridge, Mo: e.target.value });
                    }}
                    placeholder="Vật liệu mố..."></input>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Trụ</h6>
                  <div className="spacer"></div>
                  <input
                    className="form-control w-75-percent"
                    value={bridge.Tru}
                    onChange={(e) => {
                      setBridge({ ...bridge, Tru: e.target.value });
                    }}
                    placeholder="Vật liệu trụ..."></input>
                </div>
              </div>
            </div>

            {/* Kích thước cầu */}
            <div className="mt-4">
              <DivideHeader targetColapse="div-kich-thuoc" expanded={true}>
                KÍCH THƯỚC CẦU
              </DivideHeader>

              <div id="div-kich-thuoc" className="collapse show">
                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Chiều dài nhịp</h6>
                  <div className="spacer"></div>
                  <InputPrefix
                    prefix="m"
                    className="form-control w-75-percent"
                    value={bridge.ChieuDaiNhip}
                    onChange={(e) => {
                      setBridge({ ...bridge, ChieuDaiNhip: e });
                    }}
                    placeholder="Nhập chiều dài nhịp..."
                    type="number"></InputPrefix>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Bề rộng xe chạy (m)</h6>
                  <div className="spacer"></div>
                  <InputPrefix
                    prefix="m"
                    className="form-control w-75-percent"
                    value={bridge.BeRongXeChay}
                    onChange={(e) => {
                      setBridge({ ...bridge, BeRongXeChay: e });
                    }}
                    placeholder="Nhập bề rộng xe chạy..."
                    type="number"></InputPrefix>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Khoảng cách dầm chính (m)</h6>
                  <div className="spacer"></div>
                  <InputPrefix
                    prefix="m"
                    className="form-control w-75-percent"
                    value={bridge.KhoangCachDamChinh}
                    onChange={(e) => {
                      setBridge({ ...bridge, KhoangCachDamChinh: e });
                    }}
                    placeholder="Nhập khoảnh cách dầm chính..."
                    type="number"></InputPrefix>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Khoảng cách dầm ngang (m)</h6>
                  <div className="spacer"></div>
                  <InputPrefix
                    prefix="m"
                    className="form-control w-75-percent"
                    value={bridge.KhoanCachDamNgang}
                    onChange={(e) => {
                      setBridge({ ...bridge, KhoanCachDamNgang: e });
                    }}
                    placeholder="Nhập khoảng cách dầm ngang..."
                    type="number"></InputPrefix>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Bề rộng lề bộ hành (m)</h6>
                  <div className="spacer"></div>

                  <InputPrefix
                    prefix="m"
                    className="form-control w-75-percent"
                    value={bridge.ChieuCaoBanMatCau}
                    onChange={(e) => {
                      setBridge({ ...bridge, ChieuCaoBanMatCau: e });
                    }}
                    placeholder="Nhập chiều cao bản mặt cầu..."
                    type="number"></InputPrefix>
                </div>

                <div className="d-flex mt-2 align-items-center">
                  <h6 className="m-0">Bề rộng lan can (m)</h6>
                  <div className="spacer"></div>

                  <InputPrefix
                    prefix="m"
                    className="form-control w-75-percent"
                    value={bridge.BeRongLanCan}
                    onChange={(e) => {
                      setBridge({ ...bridge, BeRongLanCan: e });
                    }}
                    placeholder="Nhập bề rộng lan can..."
                    type="number"></InputPrefix>
                </div>
              </div>
            </div>

            {/* Lịch sử sửa chữa */}
            <div className="mt-4">
              <DivideHeader targetColapse="div-sua-chua" color="warning" expanded={true}>
                SỬA CHỮA/KIỂM TRA
              </DivideHeader>

              <div id="div-sua-chua" className="collapse show">
                <div className="card">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Ngày kiểm tra</th>
                        <th className="d-none">Đơn vị kiểm tra</th>
                        <th>Loại hư hỏng</th>
                        <th>Ngày sửa chữa</th>
                        <th className="d-none">Đơn vị sửa chữa</th>
                        <th>Chi phí sửa chữa</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {bridge.BridgeRepairs.map((x) => (
                        <tr>
                          <td className="py-0">{x.NgayKiemTra.toVnShortString()}</td>
                          <td className="d-none">{x.DonViKiemTra}</td>
                          <td className="py-0">{x.LoaiHuHong}</td>
                          <td className="py-0">{x.NgaySuaChua.toVnShortString()}</td>
                          <td className="py-0 d-none">{x.DonViSuaChua}</td>
                          <td className="py-0">{x.ChiPhiSuaChua}</td>
                          <td className="p-0 autosize-column">
                            <div className="d-flex">
                              <button className="btn btn-light" title="Chi tiết" onClick={() => showDetail(x.RepairHistoryId)}>
                                <span className="bi bi-info-square text-primary"></span>
                              </button>
                              <button className="btn btn-light" title="Xóa" onClick={() => deleteRepair(x.RepairHistoryId)}>
                                <span className="bi bi-x-square text-danger"></span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="mt-2 d-flex">
                <div className="spacer"></div>
                <button className="btn btn-outline-success" onClick={() => addKiemTraSuaChua()}>
                  <span className="bi bi-plus text-success me-2"></span>Thêm kiểm tra/sửa chữa
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BridgeEditComponent;

BridgeEditComponent.defaultProps = {
  isEditing: true,
};
