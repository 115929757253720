import React, { useState } from 'react'
import toastr from 'toastr';
import { requestLogin } from '../../ApiService/Authentication/AuthService';
import { useNavigate } from "react-router-dom";

import './Login.css';

export default function LoginPage() {
    const [userName, setUserName] = useState("")
    const [passWord, setPassWord] = useState("")
    let navigate = useNavigate();

    function login(){
        requestLogin({
            UserName: userName,
            Password: passWord
        }, (res) => {
            //toastr.success("Đăng nhập thành công!");
            localStorage.setItem('token', res.Result.Token);
            navigate("/");
        })
    }

    return (
        <div id='login-page'>
            <div className="text-center">
                <div className="form-signin">
                    <img src="/images/app_logo.png" width="250" height="150" style={{objectFit: 'contain'}} alt="logo"/>
                    <h6 className="text-center">
                        Tem công nghệ HEVO-RFID
                    </h6>
                    <input type="text" id="inputEmail" onChange={(e) => setUserName(e.target.value)} className="form-control" placeholder="Tài khoản" required autoFocus />
                    <input type="password" id="inputPassword"  onChange={(e) => setPassWord(e.target.value)} className="form-control" placeholder="Mật khẩu" required />

                    <button onClick={login} className="btn btn-lg btn-primary btn-block w-100" type="submit" style={{backgroundColor: '#007bff', borderColor: '#007bff'}}>
                        <span className="text-center w-100" style={{fontSize: "1.2rem"}}>Đăng nhập</span>
                    </button>
                </div>
            </div>
        </div>
    );
}
