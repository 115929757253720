import React, { useRef, useState } from 'react'
import InputFile from './InputFile';

export default function AddImageButton(props) {
    function onFileUploaded(result) {
        if (props.onFileAdded !== undefined) {
            props.onFileAdded(result);
        }
    }

    const [trigger, setTrigger] = useState(true)

    return (
        <div>
            <InputFile className='d-none' trigger={trigger} onFileUploaded={onFileUploaded}></InputFile>

            <span onClick={() => { setTrigger(!trigger) }} className='bi bi-plus-square-dotted text-center text-secondary w-100 hand' title='Thêm hình ảnh' style={{ fontSize: '100px' }}></span>
        </div>
    )
}
